import React from 'react';

import styles from './Blog.module.css';

import ReactPlayer from 'react-player';
const BlogVideo = ({ videoURL }) => {
	return (
		<ReactPlayer
			url={videoURL}
			config={{
				youtube: {
					playerVars: { modestbranding: 1 },
					noCookie: true,
					embedOptions: {
						host: 'https://www.youtube-nocookie.com',
					},
				},
			}}
			className={styles.videoWrapper}
			playing={false}
			muted
			loop
			controls
			width='100%'
			height='100%'
		/>
	);
};

export default BlogVideo;
