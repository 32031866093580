import React, { useState } from 'react';
import {
	Typography,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Link,
} from '@mui/material';
import Flag from 'react-world-flags';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
	const [language, setLanguage] = useState('EN');
	const handleChange = (event) => {
		setLanguage(event.target.value);
	};

	const EnglishContent = () => {
		return (
			<div className={styles.content}>
				<div className={styles.titleContainer}>
					<Typography variant='h4' align='center' className={styles.title}>
						Privacy Policy
					</Typography>
				</div>
				<div className={styles.infoContainer}>
					<Typography variant='subtitle1' align='center'>
						Establishes Mediatel Data approach to compliance with data
						protection laws.
					</Typography>
					<Typography variant='subtitle1' align='center'>
						It does not replace any specific data protection requirements that
						might apply to a business unit or function. This Policy describes
						how Personal Data will be Processed to meet Mediatel Data protection
						standards and to comply with privacy laws and regulations.
					</Typography>

					<Typography variant='h6'>Our principles as Controler:</Typography>
					<ul>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will ensure that all Processing is carried out in
								accordance with applicable laws.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will inform and explain to individuals, at the
								time when their Personal Data is collected, how their Personal
								Data will be Processed.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will only obtain and Process Personal Data for
								those purposes which are known to the individual or which are
								based on an actual collaboration and are relevant to Mediatel
								Data.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will only Process Personal Data for specified,
								explicit and legitimate purposes and not further Process that
								information in a manner that is incompatible with those
								purposes, unless such further Processing is consistent with the
								applicable law of the country in which the Personal Data was
								collected.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will only keep Personal Data for as long as is
								necessary for the purposes for which it is collected and further
								Processed.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will implement appropriate technical and
								organizational measures to ensure a level of security of
								Personal Data that is appropriate to the risk to the rights and
								freedoms of individuals.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will comply with data security breach notification
								requirements as required under applicable law.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will adhere to the Data Subject rights procedure
								and will respond to any requests from individuals to access
								their Personal Data in accordance with applicable law.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will also deal with requests to rectify or erase
								inaccurate or incomplete Personal Data, or to cease Processing
								Personal Data in accordance with the Data Subject rights
								procedure.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will not transfer Personal Data to third parties
								outside Europe.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will allow customers to opt-out of receiving
								marketing information.
							</Typography>
						</li>
					</ul>

					<Typography variant='h6'>
						Our principles as Processor / Empowered Person/ Provider:
					</Typography>
					<ul>
						<Typography variant='h6'>Lawfulness of Processing</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will ensure that all Processing is carried out in
								accordance with applicable laws.
							</Typography>
							<Typography variant='subtitle1'>
								Mediatel Data will cooperate and assist a Data Controller to
								comply with its obligations under applicable data protection
								laws.
							</Typography>
						</li>
						<Typography variant='h6'>Fairness and Transparency</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will assist a Data Controller to comply with the
								requirement to inform and explain to individuals how their
								Personal Data will be Processed in accordance with applicable
								laws.
							</Typography>
						</li>
						<Typography variant='h6'>Purpose Limitation</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will only Process Personal Data on behalf of, and
								in accordance with, the instructions of a Data Controller.
							</Typography>
						</li>
						<Typography variant='h6'>Data Minimization and Accuracy</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will assist a Data Controller to keep the Personal
								Data accurate and up to date.
							</Typography>
						</li>
						<Typography variant='h6'>
							Limited Retention of Personal Data
						</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will only keep Personal Data for as long as is
								necessary under the terms of the contract or other legally
								binding document with a Data Controller.
							</Typography>
						</li>
						<Typography variant='h6'>Security and Confidentiality</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will implement appropriate technical and
								organizational measures to safeguard Personal Data processed on
								behalf of a Data Controller.
							</Typography>
							<Typography variant='subtitle1'>
								Mediatel Data will notify a Data Controller without undue delay
								of any security breach affecting the Personal Data that is being
								Processed on behalf of a Data Controller in accordance with the
								terms of the contract or other legally binding document with
								that Data Controller.
							</Typography>
							<Typography variant='subtitle1'>
								Mediatel Data will comply with the requirements of a Data
								Controller regarding the appointment of any sub-processor.
							</Typography>
						</li>
						<Typography variant='h6'>Rights of Individuals</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will assist Data Controllers to comply with their
								duty to respect the rights of individuals based on the existing
								collaboration - contract.
							</Typography>
						</li>
					</ul>

					<Typography variant='h4' align='center' className={styles.title}>
						Organizational Technical Measures
					</Typography>

					<ol>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data takes measures to safely dispose of Personal Data
								and property (eg equipment) containing Personal Data, taking
								into account the available technology on the market. All
								computers/ equipment used have anti-virus programs and other
								security systems to prevent security incidents, access to
								customer data is through a secure, pass-lock network for
								workstations;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data is limiting Personnel Access to Personal Data:
								Mediatel Data has taken reasonable steps to ensure that the
								staff assigned to each operations meets the legal requirements
								for performing the activities and has granted the minimum level
								(s) of Access to Data with Personal Character, which are
								necessary to fulfill the necessary duties in order to implement
								the Mediatel Data obligations, according to the contracts in
								force;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data staff has been properly trained in the proper
								processing of Personal Data and security measures as the new
								GDPR regulation requires; staff are subject to written privacy
								obligations with respect to Personal Data and process Personal
								Data only in accordance with their instructions and secure
								access provided by them;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Datal undertakes to maintain access register for all
								physical access levels in the premises, as well as logical logs
								to the equipment that manages Personal Data. Visitors will be
								registered at the entrance and exit of the Mediatel Data
								location which also has all the security measures applied.
								During the stay at the location, they will be permanently
								accompanied by the employees;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will strictly access the information provided by
								our clients, strictly according to its instructions and purpose;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data will only provide the services stipulated in the
								parent contract for the Mediatel Data software product, for any
								additional action requested or which infringes the GDPR
								regulations, we reserve the right to refuse, not to act;
							</Typography>
						</li>
					</ol>
				</div>

				<div className={styles.disclaimerContainer}>
					<Typography variant='h6'>
						Mediatel Data reserves the right to change, modify or update this
						Policy at any time, according to the current law applied.
					</Typography>
					<Typography variant='h6'>
						Please review it frequently for any updates.
					</Typography>
					<Typography variant='h6'>
						The EU general data protection regulation 2016/679 (GDPR) will take
						effect starting from 25 May 2018.
					</Typography>
					<Typography variant='h6'>
						You can get more information by writing to{' '}
						<Link href='mailto:dpo@mediatel.ro'>dpo@mediatel.ro</Link>
					</Typography>
				</div>
			</div>
		);
	};

	const RomanianContent = () => {
		return (
			<div className={styles.content}>
				<div className={styles.titleContainer}>
					<Typography variant='h4' align='center' className={styles.title}>
						Politica de confidențialitate
					</Typography>
				</div>
				<div className={styles.infoContainer}>
					<Typography variant='subtitle1' align='center'>
						Stabilește abordarea Mediatel Data privind respectarea legilor
						privind protecția datelor.
					</Typography>
					<Typography variant='subtitle1' align='center'>
						Acesta nu înlocuiește cerințele specifice de protecție a datelor
						care ar putea fi aplicate unei unități sau unei funcții. Această
						politică descrie modul în care datele personale vor fi prelucrate
						pentru a respecta standardele de protecție a datelor Mediatel și
						pentru a respecta legile și reglementările privind
						confidențialitatea acestora.
					</Typography>

					<Typography variant='h6'>
						Principiile noastre in calitate de Controlor:
					</Typography>
					<ul>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data se va asigura că toate prelucrările sunt efectuate
								în conformitate cu legile aplicabile.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va informa și va explica persoanelor fizice, în
								momentul colectării datelor lor personale, modul în care datele
								lor personale vor fi prelucrate.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va obține și va procesa date personale numai în
								acele scopuri care sunt cunoscute persoanei sau care se bazează
								pe o colaborare reală și care sunt relevante pentru companie.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va procesa numai datele personale în scopuri
								specificate, explicite și legitime și nu vor procesa în
								continuare aceste informații într-o manieră incompatibilă cu
								aceste scopuri, cu excepția cazului în care o astfel de
								prelucrare ulterioară este conformă cu legislația aplicabilă a
								țării în care au fost colectate datele cu caracter personal.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va păstra datele cu caracter personal numai atâta
								timp cât este necesar pentru scopurile pentru care sunt
								colectate și ulterior prelucrate.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va implementa măsuri tehnice și organizatorice
								adecvate pentru a asigura un nivel de securitate a datelor
								personale adecvat riscului pentru drepturile și libertățile
								persoanelor.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va respecta cerințele de notificare a încălcării
								securității datelor conform cerințelor legii aplicabile.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va adera la procedura privind drepturile de autor
								și vor răspunde oricăror solicitări din partea persoanelor
								fizice de a accesa datele lor personale în conformitate cu
								legislația în vigoare.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data se va ocupa, de asemenea, de cererile de
								rectificare sau de ștergere a datelor cu caracter personal
								inexacte sau incomplete sau de încetarea prelucrării datelor cu
								caracter personal în conformitate cu procedura privind
								drepturile de date.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data nu va transfera date personale către terțe părți
								din afara Europei.
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va permite clienților să renunțe la primirea
								informațiilor de marketing.
							</Typography>
						</li>
					</ul>

					<Typography variant='h6'>
						Principiile noastre in calitate de Procesator / Persoană
						împuternicită / Furnizor / Prestator:
					</Typography>
					<ul>
						<Typography variant='h6'>Legea procesării</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va asigura că toate prelucrările sunt efectuate în
								conformitate cu legile aplicabile.
							</Typography>
							<Typography variant='subtitle1'>
								Mediatel Data va coopera și va asista un controlor de date
								pentru a-și respecta obligațiile care îi revin în conformitate
								cu legile aplicabile privind protecția datelor.
							</Typography>
						</li>
						<Typography variant='h6'>Corectitudinea și transparența</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va ajuta un controlor de date să respecte cerința
								de a informa și de a explica persoanelor cum vor fi prelucrate
								datele lor personale în conformitate cu legile aplicabile.
							</Typography>
						</li>
						<Typography variant='h6'>Limitarea scopului</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va procesa date personale numai în numele și în
								conformitate cu instrucțiunile unui controlor de date.
							</Typography>
						</li>
						<Typography variant='h6'>
							Minimizarea și acuratețea datelor
						</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va ajuta un controlor de date să păstreze datele
								personale corecte și actualizate.
							</Typography>
						</li>
						<Typography variant='h6'>
							Limitarea păstrării datelor cu caracter personal
						</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va păstra datele cu caracter personal numai atâta
								timp cât este necesar în condițiile contractului sau în alt
								document obligatoriu din punct de vedere juridic cu un controlor
								de date.
							</Typography>
						</li>
						<Typography variant='h6'>
							Securitate și confidențialitate
						</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va implementa măsuri tehnice și organizatorice
								adecvate pentru a proteja datele personale prelucrate în numele
								unui controlor de date.
							</Typography>
							<Typography variant='subtitle1'>
								Mediatel Data va notifica un controlor de date, fără întârzieri
								nejustificate, orice încălcare a securității care afectează
								datele personale care sunt procesate în numele unui controlor de
								date în conformitate cu termenii contractului sau cu un alt
								document obligatoriu din punct de vedere juridic cu respectivul
								controlor de date.
							</Typography>
							<Typography variant='subtitle1'>
								Mediatel Data va respecta cerințele unui controlor de date în
								ceea ce privește numirea unui subprocesor.
							</Typography>
						</li>
						<Typography variant='h6'>Drepturile persoanelor</Typography>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va ajuta controlorii de date să își respecte
								obligația de a respecta drepturile persoanelor bazate pe
								contractul de colaborare existent.
							</Typography>
						</li>
					</ul>

					<Typography variant='h4' align='center' className={styles.title}>
						Măsuri tehnice organizatorice
					</Typography>

					<ol>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data ia măsuri pentru a dispune de date personale și
								proprietăți (de exemplu, echipamente) care conțin date cu
								caracter personal, luând în considerare tehnologia disponibilă
								pe piață. Toate computerele / echipamentele si statiile de lucru
								folosite au programe antivirus și alte sisteme de securitate
								pentru a preveni incidentele de securitate, accesul la datele
								clienților se realizează printr-o rețea securizată intotdeauna;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data limitează accesul personalului la date personale:
								Mediatel Data a luat măsuri rezonabile pentru a se asigura că
								personalul alocat fiecărei operațiuni îndeplinește cerințele
								legale pentru desfășurarea activităților și a acordat nivelurile
								minime de acces la date cu caracter personal, pentru
								îndeplinirea sarcinilor necesare si punerea în aplicare a
								obligațiilor Mediatel Data, conform contractelor în vigoare;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Personalul Mediatel Data a fost instruit corespunzător în
								prelucrarea corectă a datelor cu caracter personal și a
								măsurilor de securitate, așa cum prevede noul regulament GDPR;
								personalul este supus obligațiilor scrise de confidențialitate
								cu privire la datele cu caracter personal și datele personale de
								proces numai în conformitate cu instrucțiunile și accesul
								securizat furnizat de acestea;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Datal se obligă să mențină registrul de acces pentru
								toate nivelurile de acces fizic din incintă, precum și jurnalele
								logice ale echipamentului care gestionează datele personale.
								Vizitatorii vor fi înregistrați la intrarea și ieșirea din
								locația Mediatel Data, care are, de asemenea, toate măsurile de
								securitate aplicate. În timpul șederii la locul respectiv, vor
								fi însoțiți în permanență de angajați;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va accesa strict informatiile furnizate de
								clientii nostri strict conform instructiunilor si scopului
								acestuia;
							</Typography>
						</li>
						<li>
							<Typography variant='subtitle1'>
								Mediatel Data va furniza numai serviciile prevazute in
								contractul principal pentru produsul software Mediatel Data,
								pentru orice acțiune suplimentară solicitată sau care încalcă
								regulamentul GDPR, ne rezervăm dreptul de a refuza, de a nu
								acționa;
							</Typography>
						</li>
					</ol>
				</div>

				<div className={styles.disclaimerContainer}>
					<Typography variant='h6'>
						Mediatel Data își rezervă dreptul de a schimba, modifica sau
						actualiza această politică în orice moment.
					</Typography>
					<Typography variant='h6'>
						Revedeți-l frecvent pentru actualizare și informare curentă.
					</Typography>
					<Typography variant='h6'>
						Regulamentul general UE privind protecția datelor 2016/679 (GDPR) a
						intrat în vigoare în data de 25 mai 2018.
					</Typography>
					<Typography variant='h6'>
						Puteți obține mai multe informații la{' '}
						<Link href='mailto:dpo@mediatel.ro'>dpo@mediatel.ro</Link>
					</Typography>
				</div>
			</div>
		);
	};

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<div className={styles.languageContainer}>
					<FormControl fullWidth variant='outlined'>
						<InputLabel id='demo-simple-select-label'>
							Select language
						</InputLabel>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							value={language}
							onChange={handleChange}
							label='Select language'
							fullWidth
						>
							<MenuItem value='EN'>
								<div className={styles.option}>
									<Flag code={'US'} height='20' />
								</div>
							</MenuItem>
							<MenuItem value='RO'>
								<div className={styles.option}>
									<Flag code={'RO'} height='20' />
								</div>
							</MenuItem>
						</Select>
					</FormControl>
				</div>

				{language === 'EN' ? <EnglishContent /> : <RomanianContent />}
			</div>
		</div>
	);
};

export default PrivacyPolicy;
