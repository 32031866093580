import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Slide } from 'react-awesome-reveal';
import styles from './Testimonials.module.css';

export const Testimonials = () => {
	const quotes = {
		0: {
			client: 'Robert Soszka',
			company: 'Delta Contact',
			quote:
				'The Mediatel Call Center system is amazing, offering us all the features that we are looking for. No matter what new requests we come up with, Mediatel specialists are ready to customize and develop for us new functionalities.',
		},
		1: {
			client: 'David Wu',
			company: 'UAT',
			quote:
				'We are looking for an advanced predictive dialer. The completeness and user-friendly interface of the Mediatel Call Center system made it a perfect choice for us. With all-in-one functionalities, Mediatel has certainly exceeded my expectations.',
		},
		2: {
			client: 'Cosmin Raducu Efrim',
			company: 'Global Remote Services',
			quote:
				'We are working with Mediatel for more than 9 years now and we are grateful that we managed together to develop this longstanding partnership. We thank Mediatel for all their support.',
		},
		3: {
			client: 'Thomasz Borowski',
			company: 'Platinum Bank',
			quote:
				'Implementation of Mediatel Data Call Center was made with high quality and within a short time – just over 3 months. As a result, Platinum Bank got reliable and scalable system integrated with existing banking systems.',
		},
		4: {
			client: 'Sebastian Cmak',
			company: 'EOS',
			quote:
				"Mediatel's highly dedicated team is always responsive to our fluctuating needs and demands. They have helped us scale quickly with minimal expansion to our infrastructure and staff. I highly recommend Mediatel products and services.",
		},
	};

	const [current, setCurrent] = useState(quotes[0]);
	const [active, setActive] = useState(0);

	const handleClick = (event) => {
		setCurrent(quotes[event.target.getAttribute('data-quote')]);
		setActive(event.target.getAttribute('data-quote'));
	};

	return (
		<div className={styles.section}>
			<Slide>
				<div className={styles.container}>
					<Typography variant='h6' className={styles.quote}>
						{current.quote}
					</Typography>
					<Typography variant='h6' className={styles.client}>
						{current.client}
					</Typography>
					<Typography variant='subtitle1' className={styles.company}>
						{current.company}
					</Typography>
					<div className={styles.indicators}>
						{Object.keys(quotes).map((index) => (
							<span
								onClick={(event) => handleClick(event)}
								data-quote={index}
								className={index === active ? styles.active : null}
								key={index}
							></span>
						))}
					</div>
				</div>
			</Slide>
		</div>
	);
};
export default Testimonials;
