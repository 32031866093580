import React from 'react';
import { Typography } from '@mui/material';
import styles from './Content.module.css';

const Content = () => {
	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<div className={styles.textContainer}>
					<Typography variant='h4' className={styles.bigTitle}>
						Virtual Assistant through
						<br /> <span className={styles.customText}>Mediatel Data</span>{' '}
						Business Model
					</Typography>
				</div>
				<div className={styles.infoContainer}>
					<Typography variant='h6'>
						During the past year, Mediatel Data has laid the foundation of an{' '}
						<strong>Artificial Intelligence</strong> department that develops
						smart solutions dedicated to the field in which we operate. The need
						for this department arose from the needs expressed by our customers,
						that have guided, as always so far, our decisions and products.
					</Typography>
					<Typography variant='h6'>
						The role of the AI Department is to boost practical applications
						with research-inspired ideas. By working closely with the
						development and support departments, we have designed and
						implemented Machine Learning-based modules for the{' '}
						<strong>Mediatel Data Call & Contact Center software</strong>, all
						gathered under the concept of Virtual Assistant.
					</Typography>
					<Typography variant='h6'>
						Ranging from identifying the intents of customers that contact a
						business via several channels (voice call, video call, e-mail,
						WhatsApp, Facebook, etc.), understanding the topics that they are
						interested in, analyzing their attitudes or proving their identity
						(via voice biometrics or facial recognition), we have integrated
						numerous new technologies, as part of our continuous efforts to
						offer the best products to our clients.
					</Typography>
					<Typography variant='h6'>
						The <strong>Mediatel Data Virtual Assistant</strong> has already
						been embraced by some of our customers. This ensured access to
						production data, thus allowing us to train from the very beginning
						accurate and effective AI models.
					</Typography>
					<Typography variant='h6'>
						Moreover, due to the dynamicity imposed by 2020, we have challenged
						ourselves and went even one a step further, by developing 100%
						personalized solutions in a quick yet professional manner: in just
						one year, we have succeeded in customizing our AI solutions for
						banking, accounting, telephony, energy management, insurances and
						medical services.
					</Typography>
					<Typography variant='h6'>
						Our AI modules are entirely developed in-house: trained on our
						systems—not on proprietary cloud platforms, with real data that we
						collect from our clients. Thus, the client is not obliged to buy
						third-party software, the models are more flexible and can be easily
						adapted to the clients' needs. Finally, the deployment can be{' '}
						<strong>100% on-premise</strong>, on the clients' servers, thus
						complying even with the most severe security constraints.
					</Typography>
					<Typography variant='h6'>
						Secondly, our <strong>Machine Learning</strong> models are dynamic.
						Most companies offer pre-trained models that can solve fixed tasks,
						without investing any effort in improving them over time. Our{' '}
						<strong>Virtual Assistant</strong>, however, is designed to allow
						constant improvements. When landing a model in production, we
						accompany it by feedback mechanisms through which the users can
						signal the misclassifications. This information is then used to
						periodically re-train the model. Hence, we make sure that the models
						are always complying with the most up-to-date business needs.
					</Typography>
					<Typography variant='h6'>
						Last but not at least, it has become popular among businesses to
						offer a <strong>"good at everything"</strong> assistant that has the
						same core tied to different input/ output sources (text, video,
						voice).
					</Typography>
					<Typography variant='h6'>
						Nevertheless, the <strong>Mediatel Data Virtual Assistant</strong>{' '}
						is never personalized for a customer by having a single workflow in
						mind: the performance of our product lines in our awareness of the
						fact that different channels require separate flows. For example, we
						have specialized models for both written and spoken text—and that’s
						how we succeed in capturing both basic and subtle features.
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default Content;
