import React from 'react';
import { Typography, Link } from '@mui/material';
import styles from './News.module.css';
import { newsList } from './NewsList';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

import { Slide } from 'react-awesome-reveal';

const News = () => {
	const lastNews = newsList.slice(0, 3);

	const LastNewsContainer = () => {
		return (
			<div className={styles.lastNewsSection}>
				<Slide>
					<div className={styles.textContainer}>
						<Typography variant='h4' className={styles.bigTitle}>
							Check out the company
							<br /> latest News
						</Typography>
						<div className={styles.followContainer}>
							<Typography variant='subtitle1' className={styles.text}>
								Follow us
							</Typography>
							<div className={styles.followSocial}>
								<Link
									to='/#'
									href='https://www.facebook.com/mediateldata'
									target='_blank'
									rel='noopener'
									color='secondary'
								>
									<FacebookIcon
										fontSize='small'
										className={styles.socialIcon}
									/>
								</Link>
								<Link
									to='/#'
									href='https://www.instagram.com/mediateldata/'
									target='_blank'
									rel='noopener'
									color='secondary'
								>
									<InstagramIcon
										fontSize='small'
										className={styles.socialIcon}
									/>
								</Link>
								<Link
									to='/#'
									href='https://www.linkedin.com/company/mediatel-data-srl/'
									target='_blank'
									rel='noopener'
									color='secondary'
								>
									<LinkedInIcon
										fontSize='small'
										className={styles.socialIcon}
									/>
								</Link>
								<Link
									to='/#'
									href='https://twitter.com/MediatelD'
									target='_blank'
									rel='noopener'
									color='secondary'
								>
									<TwitterIcon fontSize='small' className={styles.socialIcon} />
								</Link>
							</div>
						</div>
					</div>
				</Slide>

				<Slide top>
					<div className={styles.lastNewsContainer}>
						{lastNews.map((item, index) => {
							return (
								<div key={index} className={styles.lastNews}>
									<div
										className={styles.imageContainer}
										style={{ background: `url(${item.image})` }}
									>
										<div className={styles.dateContainer}>
											<Typography variant='subtitle2' className={styles.date}>
												{item.date}
											</Typography>
										</div>
									</div>

									<div className={styles.infoContainer}>
										<Typography variant='subtitle2' className={styles.location}>
											<LocationOnIcon className={styles.icon} />
											{item.location}
										</Typography>

										<Typography variant='subtitle1' className={styles.title}>
											<Link to='/#' href={item.link} target='_blank'>
												{item.title}
											</Link>
										</Typography>
									</div>
								</div>
							);
						})}
					</div>
				</Slide>
			</div>
		);
	};

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<LastNewsContainer />
			</div>
		</div>
	);
};

export default News;
