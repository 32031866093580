import React from 'react';
import { Typography, Button } from '@mui/material';
import styles from './Advantages.module.css';
import { Slide } from 'react-awesome-reveal';

const Advantages = () => {
	const advantages = [
		{
			title: 'Get your Clarification',
			subtitle:
				'Get your doubts cleared by contacting or by reading the resources from our support team and knowledge base.',
			icon: '',
		},
		{
			title: 'Legendary Support',
			subtitle: 'We use our 24/7 program to be there for you.',
			icon: '',
		},
		{
			title: 'Maintenance Package',
			subtitle: 'We offer annual maintenance packages for different needs.',
			icon: '',
		},
	];

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<Slide>
					<div className={styles.advantagesContainer}>
						{advantages.map((advantage, index) => {
							return (
								<div className={styles.box} key={index}>
									<Typography variant='h4' className={styles.title}>
										{advantage.title}
									</Typography>
									<Typography variant='h6' className={styles.subtitle}>
										{advantage.subtitle}
									</Typography>
								</div>
							);
						})}
						<div className={styles.box}>
							<Typography variant='h6' className={styles.title}>
								We believe in the technology that we create and, in its
								potential, to accelerate communication!
							</Typography>
							<Button
								className={styles.btnSolutions}
								variant='contained'
								color='primary'
								size='small'
								href='/contact'
							>
								Contact Us
							</Button>
						</div>
					</div>
				</Slide>
			</div>
		</div>
	);
};

export default Advantages;
