import React from "react";
import styles from "./VideoAssistant.module.css";
import Header from "./Header/Header";
import Clients from "../../components/Clients/Clients";
import Introduction from "./Introduction/Introduction";
import Content from "./Content/Content";

const VideoAssistant = () => {
  return (
    <div className={styles.videoassistant}>
      <meta
        name='Mediatel Data Video Assistant'
        content='The Video Assistant brings to your customers the in-person experience while engaging in private, end-to-end secured interactive sessions with your support agents from a mobile device, tablet, or their laptop, without the need to install or download any additional software.'
      />
      <Header />
      <Clients />
      <Introduction />
      <Content />
    </div>
  );
};
export default VideoAssistant;
