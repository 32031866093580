import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
// import './chat.css';
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link as RouterLink
} from 'react-router-dom'

import About from './components/About/About'
import Blog from './components/Blog/Blog'
import EmailSignature from './components/Signature/Signature'
import ContactForm from './components/ContactForm/ContactForm'
import VideoAssistant from './components/VideoAssistant/VideoAssistant'
import VirtualAssistant from './components/VirtualAssistant/VirtualAssistant'
import Services from './components/Services/Services'
import Solutions from './components/Solutions/Solutions'
import Packages from './components/Packages/Packages'
import Navbar from './components/Navbar/Navbar'
import Homepage from './components/Homepage/Homepage'
import Footer from './components/Footer/Footer'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import CookiePolicy from './components/CookiePolicy/CookiePolicy'
import TermsOfUse from './components/TermsOfUse/TermsOfUse'
import ScrollToTop from './ScrollToTop'

import CookieBanner from 'react-cookie-banner'
import ReactGA from 'react-ga'

import { ParallaxProvider } from 'react-scroll-parallax'
import theme from './theme'

import SamsungModal from './components/SamsungModal/SamsungModal'

// const theme = createTheme();

function App () {
  const [isTransparent, setIsTransparent] = useState(true)

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsTransparent(true)
    } else if (window.scrollY >= 50) {
      setIsTransparent(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const enableGA = () => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`)
    }
  }

  const cookieMessage =
    'We use cookies on this website to give you the best experience. '

  return (
    <ThemeProvider theme={theme}>
      <SamsungModal />
      <CookieBanner
        styles={{
          banner: {
            position: 'fixed',
            bottom: '0px',
            left: '0',
            height: 'auto',
            background: '#ffffff',
            color: 'var(--text-primary-color)',
            boxShadow: 'rgb(0 0 0 / 18%) 0px -4px 10px 0px',
            display: 'flex',
            alignItems: 'center',
            zIndex: '99999999',
            padding: '20px'
          },
          message: { color: 'var(--text-primary-color)' },
          button: {
            background: 'var(--green)',
            borderRadius: '10px',
            color: '#ffffff',
            boxShadow: 'rgb(0 0 0 / 18%) 2px 2px 10px 0px'
          }
        }}
        message={cookieMessage}
        link={
          <>
            To find out more, read our{' '}
            <RouterLink to='/cookie-policy' target='_blank'>
              cookie policy
            </RouterLink>{' '}
            and{' '}
            <RouterLink to='/privacy-policy' target='_blank'>
              privacy policy
            </RouterLink>
          </>
        }
        onAccept={enableGA}
        cookie='MediatelData cookie'
      />
      <CssBaseline />
      <ParallaxProvider>
        <ScrollToTop />
        <Navbar isTransparent={isTransparent} />
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/services' element={<Services />} />
          <Route path='/solutions' element={<Solutions />} />
          <Route path='/packages' element={<Packages />} />
          <Route path='/about' element={<About />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/video-assistant' element={<VideoAssistant />} />
          <Route path='/virtual-assistant' element={<VirtualAssistant />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/cookie-policy' element={<CookiePolicy />} />
          <Route path='/terms-of-use' element={<TermsOfUse />} />
          <Route path='/email_signature' element={<EmailSignature />} />
          <Route path='/contact' element={<ContactForm />} />
        </Routes>
        <Footer />
      </ParallaxProvider>
    </ThemeProvider>
  )
}

createRoot(document.getElementById('root')).render(
  <Router>
    <App />
  </Router>
)
