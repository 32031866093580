import React from 'react';
import { Typography } from '@mui/material';

const DescriptionComponent = ({ description }) => {
	const renderText = (blockParagraph) => {
		if (blockParagraph.children && blockParagraph.children.length > 0) {
			return blockParagraph.children
				.map((spanSentence) => {
					if (spanSentence._type === 'span') {
						const marks = spanSentence.marks.map((mark) => mark);
						const text = marks.reduce((result, mark) => {
							if (mark === 'strong') {
								return `<strong>${result}</strong>`;
							} else if (mark === 'em') {
								return `<em>${result}</em>`;
							}
							return result;
						}, spanSentence.text);

						return text;
					}
					return null;
				})
				.join('');
		}
		return null;
	};

	const renderDescription = () => {
		let listType = null; // Track the current list type
		let listItems = []; // Store the list items
		let renderedContent = ''; // Final rendered content

		description.forEach((blockParagraph) => {
			if (
				blockParagraph._type === 'block' &&
				blockParagraph.style === 'normal'
			) {
				// If it's a list item
				if (blockParagraph.listItem) {
					if (!listType) {
						// If listType is null, start a new list
						listType = blockParagraph.listItem === 'bullet' ? 'ul' : 'ol';
						listItems.push(renderText(blockParagraph));
					} else if (
						listType === (blockParagraph.listItem === 'bullet' ? 'ul' : 'ol')
					) {
						// If listType matches the current list type, add the item to the current list
						listItems.push(renderText(blockParagraph));
					} else {
						// If listType doesn't match the current list type, close the previous list and start a new one
						renderedContent += `<${listType}><li>${listItems.join(
							'</li><li>'
						)}</li></${listType}>`;
						listType = blockParagraph.listItem === 'bullet' ? 'ul' : 'ol';
						listItems = [renderText(blockParagraph)];
					}
				} else {
					// If it's not a list item
					if (listType) {
						// If listType is set, close the previous list
						renderedContent += `<${listType}><li>${listItems.join(
							'</li><li>'
						)}</li></${listType}>`;
						listType = null;
						listItems = [];
					}
					renderedContent += `<p>${renderText(blockParagraph)}</p>`;
				}
			}
		});

		// If listType is still set at the end, close the final list
		if (listType) {
			renderedContent += `<${listType}><li>${listItems.join(
				'</li><li>'
			)}</li></${listType}>`;
		}

		return renderedContent;
	};

	return (
		<Typography
			variant='h6'
			dangerouslySetInnerHTML={{ __html: renderDescription() }}
		/>
	);
};

export default DescriptionComponent;
