import React, { useState } from 'react'
import { isSamsungBrowser, isFirefox } from 'react-device-detect'
import './SamsungModal.css'

const SamsungModal = () => {
  const [showModal, setShowModal] = useState(isSamsungBrowser)

  const closeModal = () => {
    setShowModal(false)
  }

  if (!showModal) {
    return null
  }

  return (
    <div className='modal-overlay'>
      <div className='modal'>
        {/* <button className='close-btn' onClick={closeModal}>
          Close
        </button> */}
        <p>
          We've detected a browser which might affect your user
          experience.Please switch to another browser. 😃
        </p>
      </div>
    </div>
  )
}

export default SamsungModal
