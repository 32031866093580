import React from "react";
import styles from "./VirtualAssistant.module.css";
import Header from "./Header/Header";
import Clients from "../Clients/Clients";
import Introduction from "./Introduction/Introduction";
import Content from "./Content/Content";

const VirtualAssistant = () => {
  return (
    <div className={styles.videoassistant}>
      <meta
        name='Mediatel Data Virtual Assistant'
        content='The Virtual Assistant solution can be deployed through both voice and messaging channels, helping to provide enhanced self-service interactions that engage every caller in natural language, not the stereotypical, robotic language that is often associated with new technologies.'
      />
      <Header />
      <Clients />
      <Introduction />
      <Content />
    </div>
  );
};
export default VirtualAssistant;
