import React from "react";
import Header from "./Header/Header";
import Items from "./Items/Items";
import Elements from "./Elements/Elements";
import Features from "./Features/Features";
import Clients from "../Clients/Clients";
import ContactForm from "../ContactForm/ContactForm";
import styles from "./Solutions.module.css";

const Solutions = () => {
  return (
    <div className={styles.solutions}>
      <meta
        name='Mediatel Data Solutions'
        content='Our forte has been to successfully tailor our solutions to support your needs whether it is in the space of Call Center, Ticketing or Artificial Intelligence.'
      />
      <Header />
      <Clients />
      <Items />
      <Features />
      <Elements />
      <ContactForm />
    </div>
  );
};
export default Solutions;
