import React from 'react';
import { Typography } from '@mui/material';

import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import styles from './Footer.module.css';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatIcon from '@mui/icons-material/Chat';

import logo from '../../_res/logo_mediatel.png';

const Footer = () => {
	const location = useLocation();

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<img src={logo} alt='logo' className={styles.logo}></img>
				<div className={styles.detailsContainer}>
					<div className={styles.mapContainer}>
						<iframe
							title='location'
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2847.8487454931083!2d26.0663299049359!3d44.45677265251572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b201f5bd847573%3A0xaff8e11a0edcaa98!2sMEDIATEL+DATA+SRL!5e0!3m2!1sro!2sro!4v1479808519995'
							width='100%'
							height='100%'
							frameBorder='0'
						></iframe>
					</div>
					<div className={styles.aboutContainer}>
						<Typography variant='subtitle2'>
							Mediatel Data is a Romanian software and services company, founded
							in 1996.
							<br />
							Our experienced specialists deliver communication software and
							dedicated services that are designed to enhance customer service
							partners' interactions, acquire and retain new clients, increase
							efficiency and improve communications.
						</Typography>
						<div className={styles.socialContainer}>
							<Typography variant='subtitle2'>Follow Us</Typography>
							<a
								href='https://www.facebook.com/mediateldata'
								rel='noreferrer'
								target='_blank'
							>
								<FacebookIcon className={styles.socialIcon} />
							</a>
							<a
								href='https://www.instagram.com/mediateldata/'
								rel='noreferrer'
								target='_blank'
							>
								<InstagramIcon className={styles.socialIcon} />
							</a>
							<a
								href='https://www.linkedin.com/company/mediatel-data-srl/'
								rel='noreferrer'
								target='_blank'
							>
								<LinkedInIcon className={styles.socialIcon} />
							</a>
							<a
								href='https://twitter.com/MediatelD'
								rel='noreferrer'
								target='_blank'
							>
								<TwitterIcon className={styles.socialIcon} />
							</a>
						</div>
					</div>
					<div className={styles.contactContainer}>
						<Typography
							variant='subtitle2'
							className={cx(styles.contactLinks, styles.contactAddress)}
						>
							<LocationOnIcon className={styles.contactIcon} />
							Chiscani no 25-27, Sector 1, Bucharest <br />
							Traian Grozavescu no. 9, Brașov
						</Typography>

						<Typography variant='subtitle2' className={styles.contactLinks}>
							<EmailOutlinedIcon className={styles.contactIcon} />
							<a href='mailto:info@mediatel.ro'>info@mediatel.ro</a>
						</Typography>

						<Typography variant='subtitle2' className={styles.contactLinks}>
							<ChatIcon className={styles.contactIcon} />
							<a href='mailto:support@mediatel.ro'>support@mediatel.ro</a>
						</Typography>

						<Typography variant='subtitle2' className={styles.contactLinks}>
							<LocalPhoneIcon size='large' className={styles.contactIcon} />
							<a href='tel:+40 21 233 45 33'>+40212334533</a>
						</Typography>
					</div>
				</div>
				<div className={styles.termsContainer}>
					<Typography variant='subtitle2' className={styles.termsLink}>
						<NavLink
							to='/privacy-policy'
							className={cx(styles.navLink, {
								[styles.activeLink]: location.pathname === '/privacy-policy',
							})}
						>
							Privacy policy
						</NavLink>
					</Typography>
					<Typography variant='subtitle2' className={styles.termsLink}>
						<NavLink
							to='/cookie-policy'
							className={cx(styles.navLink, {
								[styles.activeLink]: location.pathname === '/cookie-policy',
							})}
						>
							Cookie policy
						</NavLink>
					</Typography>
					<Typography variant='subtitle2' className={styles.termsLink}>
						<NavLink
							to='/terms-of-use'
							className={cx(styles.navLink, {
								[styles.activeLink]: location.pathname === '/terms-of-use',
							})}
						>
							Terms of use
						</NavLink>
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default Footer;
