import React from 'react';
import { Typography } from '@mui/material';
import styles from './Counter.module.css';
import { Slide } from 'react-awesome-reveal';
import moment from 'moment';

const Counter = () => {
	const yearsOfExperience = moment().diff('1996-01-01', 'years', false);
	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<Slide direction='right'>
					<div className={styles.circles}>
						<div className={styles.mainCircle}>
							<Typography variant='h1' className={styles.title}>
								{yearsOfExperience}
							</Typography>
							<Typography variant='h6' className={styles.subTitle}>
								Years Of Experience
							</Typography>
						</div>
						<div className={styles.secondaryCircle}></div>
					</div>
				</Slide>
			</div>
		</div>
	);
};
export default Counter;
