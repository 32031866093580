import React, { useState, useEffect } from 'react';
import styles from './Clients.module.css';
import { Typography, TextField } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Clients = () => {
	const [searchValue, setSearchValue] = useState('');
	const [clientData, setClientData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(process.env.REACT_APP_SANITY_URL);
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const { result } = await response.json();
				setClientData(result);
			} catch (err) {
				console.log(err);
			}
		};
		fetchData();
	}, []);

	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: Math.min(clientData?.length || 0, 5),
		slidesToScroll: 1,
		autoplay: true,
		speed: 2000,
		autoplaySpeed: 2000,
		cssEase: 'linear',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<Fade left>
					<div className={styles.textContainer}>
						<Typography variant='h4' className={styles.bigTitle}>
							We share a culture <br />
							focused on our clients
						</Typography>
						<Typography variant='h6' className={styles.bigSubtitle}>
							We make every interaction count, even the small ones.
						</Typography>
					</div>
				</Fade>

				<TextField
					style={{ opacity: 0 }}
					id='outlined-basic'
					variant='outlined'
					defaultValue={searchValue}
					label='Clients'
					onChange={(e) => setSearchValue(e.target.value)}
				/>
				{clientData.length > 0 && (
					<Slider {...settings}>
						{clientData
							.sort((a, b) => a.title.localeCompare(b.title))
							.map((item, index) => {
								return (
									<div key={index} className={styles.client}>
										<img src={item.source} alt={item.title} />
									</div>
								);
							})}
					</Slider>
				)}
				{/* <Fade bottom>
          <div className={styles.clientsContainer}>
            {clients
              .map((item, index) => {
                return (
                  <div key={index} className={styles.client}>
                    <img src={item.source} alt="logo" />
                  </div>
                );
              })
              .slice(0, 5)}
          </div>
        </Fade> */}
			</div>
		</div>
	);
};
export default Clients;
