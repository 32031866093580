import React from 'react';
import { Typography, Button } from '@mui/material';
import styles from './Advantages.module.css';
import { Slide } from 'react-awesome-reveal';

const Advantages = () => {
	const advantages = [
		{
			title: 'Competent Team',
			subtitle:
				'Our dedicated highly trained professionals are always ready to help you with any issue.',
			icon: '',
		},
		{
			title: 'Superior Solutions',
			subtitle:
				'We are committed to providing clients with the best value and solutions in the industry.',
			icon: '',
		},
		{
			title: 'Competitive Pricing',
			subtitle:
				'We provide value through straight forward commissions and added services.',
			icon: '',
		},
	];

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<Slide>
					<div className={styles.advantagesContainer}>
						{advantages.map((advantage, index) => {
							return (
								<div className={styles.box} key={index}>
									<Typography variant='h4' className={styles.title}>
										{advantage.title}
									</Typography>
									<Typography variant='h6' className={styles.subtitle}>
										{advantage.subtitle}
									</Typography>
								</div>
							);
						})}
						<div className={styles.box}>
							<Typography variant='h6' className={styles.title}>
								Mediatel Data is your choice in the world of Contact Center
								companies
							</Typography>
							<Button
								className={styles.btnSolutions}
								variant='contained'
								color='primary'
								size='small'
								href='/contact'
							>
								Contact Us
							</Button>
						</div>
					</div>
				</Slide>
			</div>
		</div>
	);
};

export default Advantages;
