import React from 'react';
import ReactPlayer from 'react-player';
// import { Link } from "react-router-dom";
import styles from './VideoPlayer.module.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
export const VideoPlayer = () => {
	ReactPlayer.removeCustomPlayers();

	const startVideo = (e) => {
		console.log('play');
		e.preventDefault();
	};

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<div className={styles.videoIcon}>
					<a
						href='https://www.youtube.com/watch?v=KZXkz69dik4&t=16s&ab_channel=MEDIATELDATA'
						target='_blank'
						rel='noreferrer'
					>
						<PlayArrowIcon fontSize='large' className={styles.iconPlay}>
							&nbsp;
						</PlayArrowIcon>
					</a>
				</div>
				<div className={styles.playerWrapper}>
					<ReactPlayer
						url='https://www.youtube-nocookie.com/watch?v=KZXkz69dik4&ab_channel=MEDIATELDATA'
						config={{
							youtube: {
								playerVars: { modestbranding: 1 },
								noCookie: true,
								embedOptions: {
									host: 'https://www.youtube-nocookie.com',
								},
							},
						}}
						className={styles.videoWrapper}
						playing
						muted
						loop
						controls
						/*  light='https://i.stack.imgur.com/zw9Iz.png'
            playIcon={
              <div className={styles.videoIcon}>
                <PlayArrowIcon
                  fontSize='large'
                  className={styles.iconPlay}
                  onClick={startVideo}
                />
              </div>
            } */
						width='100%'
						height='100%'
					/>
				</div>
			</div>
		</div>
	);
};
export default VideoPlayer;
