import React from "react";
import styles from "./Header.module.css";
import Words from "./Words";
import Advantages from "../Advantages/Advantages";

const Header = () => {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <Words />
        <Advantages />
      </div>
    </div>
  );
};
export default Header;
