import React from 'react';
// import Lottie from 'react-lottie';
import { Button, Typography } from '@mui/material';
import styles from './VirtualAssistant.module.css';
import { Fade } from 'react-awesome-reveal';

// import { animated } from '@react-spring/web';
import Lottie from 'lottie-react';

import animationData from '../../../../video/animation-virtual-assistant.json';

const VirtualAssistant = () => {
	// const defaultOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: animationData,
	// 	rendererSettings: {
	// 		preserveAspectRatio: 'xMidYMid slice',
	// 	},
	// };

	return (
		<div className={styles.container}>
			<div className={styles.infoContainer}>
				<meta
					name='Mediatel Data Video Assistant'
					content='The Virtual Assistant solution can be deployed through both voice
          and messaging channels, helping to provide enhanced self-service
          interactions that engage every caller in natural language, not the
          stereotypical, robotic language that is often associated with new
          technologies.'
				/>
				<Fade right>
					<Typography variant='h4' className={styles.bigTitle}>
						Mediatel Data
						<br /> <span className={styles.customText}>Virtual</span> Assistant
					</Typography>
					<Typography variant='h6' className={styles.bigSubtitle}>
						The Virtual Assistant solution can be deployed through both voice
						and messaging channels, helping to provide enhanced self-service
						interactions that engage every caller in natural language, not the
						stereotypical, robotic language that is often associated with new
						technologies.
					</Typography>
					<Button
						size='large'
						href='/virtual-assistant'
						variant='outlined'
						color='secondary'
					>
						Read more
					</Button>
				</Fade>
			</div>
			<div className={styles.animationContainer}>
				<Fade right>
					<Lottie
						animationData={animationData}
						loop={true}
						autoplay={true}
						width={'100%'}
						height={'auto'}
						rendererSettings={{
							preserveAspectRatio: 'xMidYMid slice',
						}}
					/>
					{/* <animated.div style={{ width: '100%', height: 'auto' }} /> */}
				</Fade>
			</div>
		</div>
	);
};
export default VirtualAssistant;
