import React from 'react'
import { Typography } from '@mui/material'
import styles from './Features.module.css'
import banner from '../../../_res/WebBanner.png'
import { Slide } from 'react-awesome-reveal'

const Features = () => {
  return (
    <div className={styles.section}>
      <Slide>
        <div className={styles.bannerContainer}>
          <img src={banner} alt='banner' className={styles.banner} />
        </div>
      </Slide>

      <div className={styles.overlay}></div>
      <div className={styles.container}>
        <Slide top>
          <div className={styles.textContainer}>
            <div className={styles.title}>
              <Typography variant='h4' className={styles.titleText}>
                Full Service
                <br /> Contact Center Technology Creator
              </Typography>
              <div className={styles.line}></div>
            </div>
            <div>
              <Typography variant='h6' className={styles.subtitle}>
                With an internal team of dedicated developers and technical
                engineers, we manage to implement the most complex systems in a
                short time by applying project management methodologies adapted
                to our specific clients. We are agile in what we do and have the
                resources necessary to develop long-term partnerships.
              </Typography>
            </div>
          </div>
        </Slide>
        <Slide bottom>
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <Typography variant='h6' className={styles.subtitle}>
                During the past year, we have laid the foundation of an
                Artificial Intelligence department that develops smart solutions
                dedicated to the field in which we operate. The need for this
                department arose from the needs expressed by our customers, that
                have guided, as always so far, our decisions and products. So
                far, we have delivered NLU solutions packed into chat and voice
                bots, customized for banking, utilities or medical services.
              </Typography>
            </div>
            <div className={styles.graphic}>
              <Typography variant='h6' className={styles.title}>
                Dedicated to all industries
              </Typography>
              <div className={styles.industriesContainer}>
                <div className={styles.industry}>
                  <Typography variant='h6'>Telemarketing</Typography>
                </div>
                <div className={styles.industry}>
                  <Typography variant='h6'>Market Research</Typography>
                </div>
                <div className={styles.industry}>
                  <Typography variant='h6'>Collections</Typography>
                </div>
                <div className={styles.industry}>
                  <Typography variant='h6'>Info Lines</Typography>
                </div>
                <div className={styles.industry}>
                  <Typography variant='h6'>Customer Service</Typography>
                </div>
                <div className={styles.industry}>
                  <Typography variant='h6'>Help Desk/ Support</Typography>
                </div>
                <div className={styles.industry}>
                  <Typography variant='h6'>Outsourcing Services</Typography>
                </div>
                <div className={styles.industry}>
                  <Typography variant='h6'>Ticketing/ reservations</Typography>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>
    </div>
  )
}

export default Features
