import React from 'react'
import Header from './Header/Header'
import Items from './Items/Items'
import Clients from '../Clients/Clients'
import ContactForm from '../ContactForm/ContactForm'
import styles from './Packages.module.css'

const Packages = () => {
  return (
    <div className={styles.packages}>
      <meta
        name='Mediatel Data Packages'
        content='We are glad to develop tailored solutions for our clients. We are open to new challenges and our experience, for more than 23 years on the market, recommends us for any kind of customization.'
      />
      <Header />
      <Clients />
      <Items />
      <ContactForm />
    </div>
  )
}
export default Packages
