import React from 'react'
import styles from './Header.module.css'
import VideoPlayer from './VideoPlayer'
import Words from './Words'

const Header = () => {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <Words />
        <VideoPlayer />
      </div>
    </div>
  )
}
export default Header
