import React from "react";
import styles from "./Header.module.css";
import Words from "./Words";
import VideoPlayer from "./VideoPlayer";

const Header = () => {
  return (
    <div className={styles.section}>
      <div className={styles.shapesContainer}>
        <div className={styles.shape1}></div>
        <div className={styles.shape2}></div>
        <div className={styles.shape3}></div>
        <div className={styles.shape4}></div>
      </div>
      <div className={styles.container}>
        <Words />
      </div>
      <VideoPlayer></VideoPlayer>
    </div>
  );
};
export default Header;
