import React from 'react';
import styles from './Words.module.css';
import { Typography } from '@mui/material';

export const Words = () => {
	return (
		<div className={styles.container}>
			<Typography variant='h4' className={styles.title}>
				Magnify your customer experience by embedding video, voice, text
				messaging and cobrowsing into one journey!
			</Typography>
			<Typography variant='h6' className={styles.subtitle}>
				The <strong>Video Assistant</strong> brings to your customers the
				in-person experience while engaging in private, end-to-end secured
				interactive sessions with your support agents from a mobile device,
				tablet, or their laptop, without the need to install or download any
				additional software.
			</Typography>
		</div>
	);
};

export default Words;
