import React from "react";
import styles from "./Assistants.module.css";
import VideoAssistant from "./VideoAssistant/VideoAssistant";
import VirtualAssistant from "./VirtualAssistant/VirtualAssistant";


const Assistants = () => {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <VideoAssistant />
        <VirtualAssistant />
      </div>
    </div>
  );
};
export default Assistants;
