import { createTheme } from '@mui/material/styles';
// import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

// const breakpoints = createBreakpoints({});

// const theme = createTheme({
// 	type: 'default',
// 	breakpoints,
// 	palette: {
// 		primary: {
// 			main: 'rgba(242, 176, 23, 0.7)',
// 			secondary: '#fabe61',
// 		},
// 		secondary: {
// 			main: '#2c7ae7',
// 			secondary: '#46a3f2',
// 		},
// 		contrastThreshold: 3,
// 		tonalOffset: 0.1,
// 	},
// 	overrides: {
// 		MuiCssBaseline: {
// 			'@global': {
// 				html: {
// 					WebkitFontSmoothing: 'auto',
// 				},
// 			},
// 		},
// 		MuiButton: {
// 			contained: {
// 				minWidth: '150px',
// 				[breakpoints.down('sm')]: {
// 					width: 'auto',
// 				},
// 			},
// 			containedPrimary: {
// 				color: '#fefefe',
// 				background:
// 					'linear-gradient(45deg, rgba(242, 176, 23, 1) 0%, rgba(242, 176, 23, 0.7) 100%);',
// 			},
// 			containedSecondary: {
// 				color: '#fefefe',
// 				background: 'linear-gradient(45deg, #0762f8 0%, #46a3f2 100%);',
// 			},
// 			root: {
// 				color: '#fefefe',
// 				textTransform: 'none',
// 				fontWeight: 'bold',
// 				borderRadius: '10px',
// 				[breakpoints.down('sm')]: {
// 					fontSize: '0.6rem',
// 					padding: '10px 8px',
// 					minWidth: '50px',
// 				},
// 				[breakpoints.down('xs')]: {
// 					fontSize: '0.6rem',
// 					padding: '5px 5px',
// 					minWidth: '30px',
// 				},
// 			},
// 		},
// 		MuiTypography: {
// 			h1: {
// 				[breakpoints.down('md')]: {
// 					fontSize: '4rem',
// 				},
// 				[breakpoints.down('sm')]: {
// 					fontSize: '3rem',
// 				},
// 				[breakpoints.down('xs')]: {
// 					fontSize: '2rem',
// 				},
// 			},
// 			h4: {
// 				[breakpoints.down('md')]: {
// 					fontSize: '1.9rem',
// 				},
// 				[breakpoints.down('sm')]: {
// 					fontSize: '1.6rem',
// 				},
// 				[breakpoints.down('xs')]: {
// 					fontSize: '1.4rem',
// 				},
// 			},
// 			h6: {
// 				[breakpoints.down('md')]: {
// 					fontSize: '1.2rem',
// 				},
// 				[breakpoints.down('sm')]: {
// 					fontSize: '1.1rem',
// 				},
// 				[breakpoints.down('xs')]: {
// 					fontSize: '1rem',
// 				},
// 			},
// 			subtitle1: {
// 				[breakpoints.down('xs')]: {
// 					fontSize: '0.8rem',
// 				},
// 			},
// 			subtitle2: {
// 				[breakpoints.down('xs')]: {
// 					fontSize: '0.7rem',
// 				},
// 			},
// 		},
// 	},
// 	typography: {
// 		// Use the system font over Roboto.
// 		fontFamily:
// 			'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
// 		htmlFontSize: 16,
// 	},
// });

const theme = createTheme({
	palette: {
		primary: {
			main: 'rgba(242, 176, 23, 0.7)',
			secondary: '#fabe61',
		},
		secondary: {
			main: '#2c7ae7',
			secondary: '#46a3f2',
		},
		contrastThreshold: 3,
		tonalOffset: 0.1,
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					WebkitFontSmoothing: 'auto',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				outlined: {
					color: '#2c7ae7',
				},
				contained: {
					minWidth: '150px',
					'@media (max-width:600px)': {
						width: 'auto',
					},
				},
				containedPrimary: {
					color: '#fefefe',
					background:
						'linear-gradient(45deg, rgba(242, 176, 23, 1) 0%, rgba(242, 176, 23, 0.7) 100%)',
					backgroundColor: 'rgba(242, 176, 23, 0.7)',
					// background:
					// 	'linear-gradient(45deg, rgba(242, 176, 23, 1) 0%, rgba(242, 176, 23, 0.7) 100%);',
				},
				containedSecondary: {
					color: '#fefefe',
					background: 'linear-gradient(45deg, #0762f8 0%, #46a3f2 100%);',
				},
				root: {
					color: '#ff0000',
					textTransform: 'none',
					fontWeight: 'bold',
					borderRadius: '10px',
					'@media (max-width:600px)': {
						fontSize: '0.6rem',
						padding: '10px 8px',
						minWidth: '50px',
					},
					'@media (max-width:400px)': {
						fontSize: '0.6rem',
						padding: '5px 5px',
						minWidth: '30px',
					},
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				h1: {
					'@media (max-width:960px)': {
						fontSize: '4rem',
					},
					'@media (max-width:600px)': {
						fontSize: '3rem',
					},
					'@media (max-width:400px)': {
						fontSize: '2rem',
					},
				},
				h4: {
					'@media (max-width:960px)': {
						fontSize: '1.9rem',
					},
					'@media (max-width:600px)': {
						fontSize: '1.6rem',
					},
					'@media (max-width:400px)': {
						fontSize: '1.4rem',
					},
				},
				h6: {
					'@media (max-width:960px)': {
						fontSize: '1.2rem',
					},
					'@media (max-width:600px)': {
						fontSize: '1.1rem',
					},
					'@media (max-width:400px)': {
						fontSize: '1rem',
					},
				},
				subtitle1: {
					'@media (max-width:400px)': {
						fontSize: '0.8rem',
					},
				},
				subtitle2: {
					'@media (max-width:400px)': {
						fontSize: '0.7rem',
					},
				},
			},
		},
	},
	typography: {
		fontFamily:
			'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
		htmlFontSize: 16,
	},
});

export default theme;
