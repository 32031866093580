import React from 'react';
import styles from './Words.module.css';
import { Typography } from '@mui/material';
import Typewriter from 'typewriter-effect';

export const Words = () => {
	return (
		<div className={styles.container}>
			<Typography variant='h1' className={styles.title}>
				Provide&nbsp;
				<Typewriter
					options={{
						strings: ['Best', 'Digital', 'Innovative '],
						autoStart: true,
						loop: true,
					}}
					onInit={(typewriter) => {
						typewriter.pauseFor(3000).deleteAll().start();
					}}
				/>
				<br />
				Services
			</Typography>
			<Typography variant='h6' className={styles.subtitle}>
				We help our clients focus on their core business and their most valuable
				asset - their customers, by offering consulting services and full
				assistance to provide a seamless contact center experience.
			</Typography>
		</div>
	);
};

export default Words;
