import React from 'react';
import { Typography, Link } from '@mui/material';
import styles from './CookiePolicy.module.css';

const CookiePolicy = () => {
	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<Typography variant='h4' className={styles.title}>
					Cookie policy
				</Typography>

				<Typography variant='subtitle1'>
					We use cookies which are tiny files that we store on your computer.
					They help us identify you from other users and allow us to give you
					the best experience when you visit our website.
				</Typography>
				<Typography variant='subtitle1'>
					We will ask you to consent to our use of cookies in accordance with
					the terms of this policy when you first visit our website.
				</Typography>

				<Typography variant='h6' className={styles.subtitle}>
					About cookies
				</Typography>
				<ul>
					<li>
						<Typography variant='subtitle1'>
							Cookies can be used by web servers to identify and track users as
							they navigate different pages on a website and identify users
							returning to a website.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							Cookies do not typically contain any information that personally
							identifies a user, but personal information that we store about
							you may be linked to the information stored in and obtained from
							cookies.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							Cookies may be either "persistent" cookies or "session" cookies: a
							persistent cookie will be stored by a web browser and will remain
							valid until its set expiry date, unless deleted by the user before
							the expiry date; a session cookie, on the other hand, will expire
							at the end of the user session, when the web browser is closed.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							A cookie is a file containing an identifier (a string of letters
							and numbers) that is sent by a web server to a web browser and is
							stored by the browser. The identifier is then sent back to the
							server each time the browser requests a page from the server.
						</Typography>
					</li>
				</ul>

				<Typography variant='h6' className={styles.subtitle}>
					Our cookies
				</Typography>

				<Typography variant='subtitle1' className={styles.type}>
					Necessary cookies
				</Typography>
				<Typography variant='subtitle1'>
					Necessary cookies help make a website usable by enabling basic
					functions like page navigation and access to secure areas of the
					website. The website cannot function properly without these cookies.
				</Typography>
				<ul>
					<li>
						<Typography variant='subtitle1'>
							<strong>Cookie name:</strong> _cfduid
						</Typography>
						<Typography variant='subtitle1'>
							<strong>Cookie purpose description:</strong> Used by the content
							network, Cloudflare, to identify trussted web traffic.
						</Typography>
					</li>
				</ul>

				<Typography variant='subtitle1' className={styles.type}>
					Statistic cookies
				</Typography>
				<Typography variant='subtitle1'>
					Statistic cookies help website owners to understand how visitors
					interact with websites by collecting and reporting information
					anonymously.
				</Typography>
				<ul>
					<li>
						<Typography variant='subtitle1'>
							<strong>Cookie name:</strong> _ga
						</Typography>
						<Typography variant='subtitle1'>
							<strong>Cookie purpose description:</strong> Registers a unique ID
							that is used to generate statistical data on how the visitor uses
							the website.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							<strong>Cookie name:</strong> _gat
						</Typography>
						<Typography variant='subtitle1'>
							<strong>Cookie purpose description:</strong> Used by Google
							Analytics to throttle request rate. the website.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							<strong>Cookie name:</strong> _gid
						</Typography>
						<Typography variant='subtitle1'>
							<strong>Cookie purpose description:</strong> Registers a unique ID
							that is used to generate statistical data on how the visitor uses
							the website.
						</Typography>
					</li>
				</ul>

				<Typography variant='subtitle1' className={styles.type}>
					Marketing cookies
				</Typography>
				<Typography variant='subtitle1'>
					Marketing cookies are used to track visitors across websites. The
					intention is to display ads that are relevant and engaging for the
					individual user and thereby more valuable for publishers and third
					party advertisers.
				</Typography>
				<ul>
					<li>
						<Typography variant='subtitle1'>
							<strong>Cookie name:</strong> collect
						</Typography>
						<Typography variant='subtitle1'>
							<strong>Cookie purpose description:</strong> Used to send data to
							Google Analytics about the visitor's device and behaviour. Tracks
							the visitor across devices and marketing channels.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							<strong>Cookie name:</strong> NID
						</Typography>
						<Typography variant='subtitle1'>
							<strong>Cookie purpose description:</strong> Registers a unique ID
							that identifies a returning user's device. The ID is used for
							targeted ads.
						</Typography>
					</li>
				</ul>

				<Typography variant='subtitle1'>
					Our analytics service provider's privacy policy is available{' '}
					<Link href='https://policies.google.com/privacy?hl=en' target='blank'>
						here
					</Link>
				</Typography>
				<Typography variant='subtitle1'>
					Sometimes, third parties manage some of our cookies, but we don't
					allow the third party to use the cookies for any purpose other than
					those listed above.
				</Typography>

				<Typography variant='h6' className={styles.subtitle}>
					More Information on Cookies
				</Typography>
				<Typography variant='subtitle1'>
					To disable cookies try consulting your software manual or some
					browsers allow you to disable and delete cookies directly through the
					browser. If you require more information on cookies, and how to
					disable or delete them go to{' '}
					<Link href='http://www.aboutcookies.org/'>
						http://www.aboutcookies.org/
					</Link>
				</Typography>
			</div>
		</div>
	);
};
export default CookiePolicy;
