import React from 'react';
import { Typography } from '@mui/material';
import { Slide } from 'react-awesome-reveal';
import styles from './FAQ.module.css';

const FAQ = () => {
	const questions = [
		{
			question: 'Do you have trial period?',
			answer:
				'Yes. We offer a Proof of Concept phase where you can test our complete environment with all configuration made for almost 30-45 days, or more.',
		},
		{
			question: 'How fast can you deliver a full solution or a product?',
			answer:
				'Our personal record is 2 days. The timeline depends on client needs and also on he’s infrastructure, availability, configuration and architecture. We are for sure at your disposal!',
		},
		{
			question: 'What kind of Customer Support do you offer?',
			answer:
				'We offer 24/ 7 support, both email and phone. Our dedicated team of experts assures consultations, integrations and all kind of technical actions where our clients request help. Trainings and documentations are included!',
		},
		{
			question: 'What SLA/ downtime Mediatel Data can assure?',
			answer: 'We assure almost 100% uptime of our solutions!',
		},
		{
			question: 'Is there a limit of calls or volume?',
			answer:
				'The limit is only set by your infrastructure. Our solutions are designed to manage unlimited connections.',
		},
		{
			question: 'How fast can I upgrade the existing resources?',
			answer:
				'Just make a call or send us an email and within a few minutes a new license will be added.',
		},
	];

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<Slide top>
					<div className={styles.textContainer}>
						<Typography variant='h6' className={styles.subtitle}>
							See what we can do for you!
						</Typography>
						<Typography variant='h4' className={styles.title}>
							Frequently Asked Questions
						</Typography>
					</div>
				</Slide>
				<Slide top>
					<div className={styles.questionsContainer}>
						{questions.map((item, index) => {
							return (
								<div key={index} className={styles.question}>
									<div className={styles.iconContainer}>?</div>
									<div>
										<Typography variant='h6' className={styles.title}>
											{item.question}
										</Typography>
										<meta
											name='Frequently Asked Questions'
											content={item.answer}
										/>
										<Typography variant='subtitle1' className={styles.subtitle}>
											{item.answer}
										</Typography>
									</div>
								</div>
							);
						})}
					</div>
				</Slide>
			</div>
		</div>
	);
};

export default FAQ;
