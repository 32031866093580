import React from 'react';
import styles from './Words.module.css';
import { Typography } from '@mui/material';
import Typewriter from 'typewriter-effect';

export const Words = () => {
	return (
		<div className={styles.container}>
			<div>
				<Typography variant='h1' className={styles.title}>
					Supply&nbsp;
					<Typewriter
						options={{
							strings: ['Efficient', 'Digital', 'Creative'],
							autoStart: true,
							loop: true,
						}}
						onInit={(typewriter) => {
							typewriter.pauseFor(3000).deleteAll().start();
						}}
					/>
					<br />
					Solutions
				</Typography>
				<Typography variant='h6' className={styles.subtitle}>
					A highly specialized team with over 25 years of experience in the Call
					& Contact Center industry with proven ability to optimally combine
					digital technology, skilled resources and efficient business processes
					is committed to implementing creative as well as proven solutions, to
					suit an ever-evolving market.
				</Typography>
			</div>
		</div>
	);
};

export default Words;
