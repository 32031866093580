import React from 'react'
import { Typography, Button } from '@mui/material'
import styles from './Advantages.module.css'
import { Slide, Bounce, Fade } from 'react-awesome-reveal'

const Advantages = () => {
  const advantages = [
    {
      title: 'Qualified Employees',
      subtitle:
        'Our team consists of more than 50 qualified and experienced consultants, developers, and testers.',
      icon: ''
    },
    {
      title: 'Free Consultations',
      subtitle:
        'Our acquaintance with a client always begins with a free consultation to find out possible solutions to their needs.',
      icon: ''
    },
    {
      title: '100% Guaranteed',
      subtitle:
        'All results that you get from us are 100% guaranteed to bring you to a whole new level of profitability and financial success.',
      icon: ''
    }
  ]

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <Slide>
          <div className={styles.advantagesContainer}>
            {advantages.map((advantage, index) => {
              return (
                <div className={styles.box} key={index}>
                  <Typography variant='h4' className={styles.title}>
                    {advantage.title}
                  </Typography>
                  <Typography variant='h6' className={styles.subtitle}>
                    {advantage.subtitle}
                  </Typography>
                </div>
              )
            })}
            <div className={styles.box}>
              <Typography variant='h6' className={styles.title}>
                Our products cost less than their analogs by other companies.
              </Typography>
              <Button
                className={styles.btnSolutions}
                variant='contained'
                color='primary'
                size='small'
                href='/contact'
              >
                Contact Us
              </Button>
            </div>
          </div>
        </Slide>
      </div>
    </div>
  )
}

export default Advantages
