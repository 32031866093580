import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import styles from "./VideoPlayer.module.css";

export const VideoPlayer = () => {
  ReactPlayer.removeCustomPlayers();

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.playerWrapper}>
          <ReactPlayer
            url='https://www.youtube-nocookie.com/embed/_bXCYf960FA'
            config={{
              youtube: {
                playerVars: { modestbranding: 1 },
                noCookie: true,
                embedOptions: {
                  host: "https://www.youtube-nocookie.com",
                },
              },
            }}
            className={styles.videoWrapper}
            playing
            muted
            loop
            controls={width > 960 ? false : true}
            width='100%'
            height='100%'
          />
        </div>
      </div>
    </div>
  );
};
export default VideoPlayer;
