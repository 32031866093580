import React from 'react';
import ImageGallery from 'react-image-gallery';
import styles from './Blog.module.css';

const BlogGallery = ({ images }) => {
	const imagesGallery = images.map((imageURL) => ({
		original: imageURL,
		thumbnail: imageURL,
	}));

	// Check if there is only one image
	const isSingleImage = images.length === 1;

	// Custom function to render the play button
	const renderPlayButton = (onClick) => {
		if (isSingleImage) {
			return null; // Hide play button for single image
		}
		return <button onClick={onClick} className='image-gallery-play-button' />;
	};

	return (
		<ImageGallery
			additionalClass={styles.gallery}
			items={imagesGallery}
			showThumbnails={!isSingleImage}
			renderPlayButton={renderPlayButton}
		/>
	);
};

export default BlogGallery;
