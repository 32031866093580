import cover53 from "../../_res/Events/53_Events/cover.jpg";
import cover54 from "../../_res/Events/54_Events/cover.png";
import cover55 from "../../_res/Events/55_Events/cover.jpg";
import cover56 from "../../_res/Events/56_Events/cover.jpg";

export const newsList = [
  {
    title:
      "Conversational Banking & Remote Work - Best Practices from strong partners",
    link: "https://www.facebook.com/mediateldata/posts/1803324843186301",
    description:
      "2 leading companies providing best technologies for your excellent customer services.",
    location: "Bucharest",
    date: "Jun 29th, 2021",
    image: cover56,
  },
  {
    title:
      "MEDIATEL DATA WINS SILVER STEVIE AWARD IN 2021 STEVIE AWARDS FOR SALES & CUSTOMER SERVICE",
    link: "https://www.facebook.com/mediateldata/posts/1689410014577785",
    description:
      "MEDIATEL DATA was presented with a SILVER Stevie Award in the Contact Center Solution - New version category in the 15th annual Stevie Awards for Sales & Customer Service.",
    location: "Fairfax, Virginia",
    date: "Feb 2nd, 2021",
    image: cover55,
  },
  {
    title: "Mediatel Data - Business or Technology predictions for 2021",
    link: "https://www.facebook.com/mediateldata/posts/1686664318185688",
    description:
      "Since the end of 2020, we have been brainstorming inside the company to set our roadmap correctly, and they have managed to put our thoughts on paper. Check the article",
    location: "Bucharest",
    date: "Feb 1st, 2021",
    image: cover54,
  },
  {
    title:
      "Roadmap - from Human Touch Contact Centre Platform to Artificial Intelligence.",
    link: "https://www.facebook.com/mediateldata/posts/1626743514177769",
    description:
      "We invite you to join our colleague Ana Cosmina Popescu at the #CCCvirtual20 today and visit our virtual booth! ",
    location: "London",
    date: "Nov 10th, 2020",
    image: cover53,
  },
];
