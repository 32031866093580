import React from 'react';
// import Lottie from 'react-lottie';
import { Button, Typography } from '@mui/material';
import styles from './VideoAssistant.module.css';

import { Fade } from 'react-awesome-reveal';

// import { animated } from '@react-spring/web';
import Lottie from 'lottie-react';
import animationData from '../../../../video/animation-video-assistant.json';

const VideoAssistant = () => {
	// const defaultOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: animationData,
	// 	rendererSettings: {
	// 		preserveAspectRatio: 'xMidYMid slice',
	// 	},
	// };

	return (
		<div className={styles.container}>
			<div className={styles.infoContainer}>
				<meta
					name='Mediatel Data Video Assistant'
					content='The Video Assistant brings to your customers the in-person experience while engaging in private, end-to-end secured interactive sessions with your support agents from a mobile device, tablet, or their laptop, without the need to install or download any additional software.'
				/>
				<Fade left>
					<Typography variant='h4' className={styles.bigTitle}>
						Mediatel Data
						<br /> <span className={styles.customText}>Video</span> Assistant
					</Typography>
					<Typography variant='h6' className={styles.bigSubtitle}>
						The Video Assistant brings to your customers the in-person
						experience while engaging in private, end-to-end secured interactive
						sessions with your support agents from a mobile device, tablet, or
						their laptop, without the need to install or download any additional
						software.
					</Typography>
					<Button
						size='large'
						href='/video-assistant'
						variant='outlined'
						color='secondary'
					>
						Read more
					</Button>
				</Fade>
			</div>
			<div className={styles.animationContainer}>
				<Fade right>
					<Lottie
						animationData={animationData}
						loop={true}
						autoplay={true}
						width={'100%'}
						height={'auto'}
						rendererSettings={{
							preserveAspectRatio: 'xMidYMid slice',
						}}
					/>
					{/* <animated.div style={{ width: '100%', height: 'auto' }} /> */}
				</Fade>
			</div>
		</div>
	);
};
export default VideoAssistant;
