import React, { useState, useRef } from 'react';
import {
	Button,
	FormControl,
	Input,
	InputLabel,
	FormHelperText,
	Typography,
	Link,
	FormLabel,
	RadioGroup,
	Radio,
	FormControlLabel,
	Snackbar,
	Alert,
} from '@mui/material';
import styles from './ContactForm.module.css';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

import ReCAPTCHA from 'react-google-recaptcha';

import { Slide } from 'react-awesome-reveal';

import { useEffect } from 'react';

const Contact = () => {
	const [open, setOpen] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [isSent, setIsSent] = useState(false);
	const [department, setDepartment] = useState('info@mediatel.ro');

	const emailURL =
		'https://p34luliwpjgzjuborfbn754ah40hboco.lambda-url.eu-central-1.on.aws/';

	const recaptchaRef = useRef();
	const [token, setToken] = useState();

	const [inputs, setInputs] = useState({
		company: '',
		name: '',
		email: '',
		phone: '',
		subject: '',
		message: '',
	});

	const resetForm = () => {
		setInputs({
			company: '',
			name: '',
			email: '',
			phone: '',
			subject: '',
			message: '',
		});
		setDepartment('info@mediatel.ro');
	};
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};
	const handleChangeDepartment = (event) => {
		setDepartment(event.target.value);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInputs((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { company, name, email, phone, subject, message } = inputs;
		/* const token = await recaptchaRef.current.executeAsync(); */
		await fetch(emailURL, {
			method: 'POST',
			body: JSON.stringify({
				company,
				name,
				email,
				phone,
				subject,
				message,
				department,
				token: token,
			}),
		})
			.then((res) => res.json())
			.then((response) => {
				if (response.body.message !== 'Success') {
					setError(`Something went wrong : ${response.body.message}`);
				} else {
					setIsSent(true);
				}
			})
			.catch((error) => {
				setError(`Something went wrong : ${error.message}`);
			});

		setOpen(true);
		setLoading(false);
		resetForm();
	};

	const verify = async () => {
		setToken(await recaptchaRef.current.executeAsync());
	};

	useEffect(() => {
		verify();
	}, []);

	return (
		<div className={styles.section}>
			<ReCAPTCHA
				sitekey={process.env.REACT_APP_SITE_KEY}
				size='invisible'
				ref={recaptchaRef}
				onChange={(res) => {
					setToken(res);
				}}
			/>
			<div className={styles.container}>
				<Slide className={styles.textContainer}>
					<div>
						<Typography variant='h4' className={styles.bigTitle}>
							Have Any Questions?
						</Typography>
						<Typography variant='h6' className={styles.bigSubtitle}>
							You can contact us in any way that is convenient for you.
						</Typography>
						<Typography variant='h6' className={styles.bigSubtitle}>
							We are available 24/7 via email or phone. You can also use the
							quick contact form below.
						</Typography>
						<Typography variant='h6' className={styles.bigSubtitle}>
							Ask Us More!
						</Typography>
						<div className={styles.contactItems}>
							<div className={styles.item}>
								<MailOutlineOutlinedIcon className={styles.iconContact} />
								<a href='mailto:info@mediatel.ro'>
									<Typography variant='h6'>info@mediatel.ro</Typography>
								</a>
							</div>
							<div className={styles.item}>
								<ChatOutlinedIcon className={styles.iconContact} />
								<a href='mailto:support@mediatel.ro'>
									<Typography variant='h6'>support@mediatel.ro</Typography>
								</a>
							</div>
							<div className={styles.item}>
								<LocalPhoneOutlinedIcon className={styles.iconContact} />
								<a href='tel:+40 21 233 45 33'>
									<Typography variant='h6'>+40212334533</Typography>
								</a>
							</div>
						</div>
					</div>
				</Slide>
				<Slide direction='right' className={styles.formContainer}>
					{error && (
						<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
							<Alert onClose={handleClose} severity='error'>
								{error}
							</Alert>
						</Snackbar>
					)}
					{isSent && (
						<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
							<Alert onClose={handleClose} severity='success'>
								Your message was sent with success!
							</Alert>
						</Snackbar>
					)}

					<form onSubmit={handleSubmit}>
						<div className={styles.row}>
							<div className={styles.box50}>
								<FormControl fullWidth>
									<InputLabel htmlFor='company-input'>Company*</InputLabel>
									<Input
										disabled={loading}
										/*  inputRef={companyRef} */
										name='company'
										id='company-input'
										aria-describedby='my-helper-text'
										type='text'
										required
										value={inputs.company}
										onChange={handleChange}
										inputProps={{
											minLength: 2,
										}}
									/>
								</FormControl>
							</div>
							<div className={styles.box50}>
								<FormControl fullWidth>
									<InputLabel htmlFor='name-input'>Name*</InputLabel>
									<Input
										disabled={loading}
										/* inputRef={nameRef} */
										id='name-input'
										type='text'
										required
										fullWidth
										name='name'
										value={inputs.name}
										onChange={handleChange}
										inputProps={{
											minLength: 2,
										}}
									/>
								</FormControl>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.box50}>
								<FormControl fullWidth>
									<InputLabel htmlFor='email-input'>Email*</InputLabel>
									<Input
										disabled={loading}
										/* inputRef={emailRef} */
										id='email-input'
										aria-describedby='my-helper-text'
										type='email'
										required
										name='email'
										value={inputs.email}
										onChange={handleChange}
									/>
									<FormHelperText id='my-helper-text'>
										We'll never share your email.
									</FormHelperText>
								</FormControl>
							</div>
							<div className={styles.box50}>
								<FormControl fullWidth>
									<InputLabel htmlFor='phone-input'>Phone</InputLabel>
									<Input
										disabled={loading}
										/* inputRef={phoneRef} */
										id='phone-input'
										type='number'
										fullWidth
										name='phone'
										value={inputs.phone}
										onChange={handleChange}
									/>
								</FormControl>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.box100}>
								<FormControl fullWidth>
									<FormLabel component='legend'>Select department</FormLabel>
									<RadioGroup
										row
										aria-label='department'
										name='department'
										value={department}
										onChange={handleChangeDepartment}
									>
										<FormControlLabel
											value='info@mediatel.ro'
											control={<Radio color='primary' />}
											label='info@mediatel.ro'
										/>
										<FormControlLabel
											value='support@mediatel.ro'
											control={<Radio color='primary' />}
											label='support@mediatel.ro'
										/>
									</RadioGroup>
								</FormControl>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.box100}>
								<FormControl fullWidth>
									<InputLabel htmlFor='subject-input'>Subject*</InputLabel>
									<Input
										disabled={loading}
										/* inputRef={subjectRef} */
										id='subject-input'
										aria-describedby='my-helper-text'
										type='text'
										required
										variant='outlined'
										size='small'
										name='subject'
										value={inputs.subject}
										onChange={handleChange}
										inputProps={{
											minLength: 2,
										}}
									/>
								</FormControl>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.box100}>
								<FormControl fullWidth>
									<InputLabel htmlFor='message-input'>Message*</InputLabel>
									<Input
										disabled={loading}
										/*  inputRef={messageRef} */
										id='message-input'
										aria-describedby='my-helper-text'
										type='text'
										required
										multiline
										rows='4'
										maxRows='6'
										name='message'
										value={inputs.message}
										onChange={handleChange}
										inputProps={{
											minLength: 1,
										}}
									/>
								</FormControl>
							</div>
						</div>

						<div className={styles.submitContainer}>
							<Button
								disabled={loading}
								type='submit'
								variant='contained'
								size='large'
								sx={{
									color: '#fefefe',
									background:
										'linear-gradient(45deg, rgba(242, 176, 23, 1) 0%, rgba(242, 176, 23, 0.7) 100%)',
									backgroundColor: 'rgba(242, 176, 23, 0.7)',
									padding: '4px 10px',
									minWidth: '150px',
									boxShadow:
										'0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
									fontWeight: 'bold',
									lineHeight: '1.75',
									borderRadius: '10px',
									textTransform: 'none',
								}}
							>
								Submit
							</Button>
							<Typography variant='subtitle2' className={styles.infoText}>
								Please take into consideration that sending an email to{' '}
								<Link href='mailto:info@mediatel.ro'>info@mediatel.ro</Link> or{' '}
								<Link href='mailto:support@mediatel.ro'>
									support@mediatel.ro
								</Link>{' '}
								will give us your consent to processing the data provided,
								strictly in the current relationship, according to the GDPR
								rules.
							</Typography>
						</div>
					</form>
				</Slide>
			</div>
		</div>
	);
};
export default Contact;
