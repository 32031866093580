import React from 'react';
import styles from './Words.module.css';
import { Typography } from '@mui/material';

export const Words = () => {
	return (
		<div className={styles.container}>
			<Typography variant='h4' className={styles.title}>
				Mediatel Data Contact Center Virtual Assistant is here to help
				organizations improve customer experience and manage contact volumes.
			</Typography>
			<Typography variant='h6' className={styles.subtitle}>
				The VA solution can be deployed through both voice and messaging
				channels, helping to provide enhanced self-service interactions that
				engage every caller in natural language, not the stereotypical, robotic
				language that is often associated with new technologies.
			</Typography>
		</div>
	);
};

export default Words;
