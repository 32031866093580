import React, { useState } from 'react';
import { Typography } from '@mui/material';
import styles from './Features.module.css';
import cx from 'classnames';

export const Features = () => {
	const features = [
		{
			title: 'CRM',
			subtitle1:
				'The CRM Application is our most versatile solution. Out-of-the-box, it comes embedded within the agent application, providing the agent with all the information and decision tools needed for solving the current case and eliminating counter-productive actions like switching applications or manual data transfer.',
			subtitle2:
				"The web application can be customized to deal with business-specific operations or processes – collection, marketing campaigns, ordering, reservations, ticketing, resource management - and to provide custom reports, aggregating data from both the call center activity and the client's existing repositories.",
		},
		{
			title: 'WFM',
			subtitle1:
				'Workforce Groups Management – call center agents can be organized in workforce groups. Human resources are split into 4 categories, Availability, Scheduler Constrains, Scheduler Preferences, and Shift trading Rules.',
			subtitle2:
				'The Queues Settings tool allows defining the workforce parameters for call center services. A call analyzer and forecasting tool is used to analyze the volume of incoming calls and call arrival distribution by months, days, and daytime intervals. Staff requirements computation, agents scheduling, the schedule views, real-time tools, time-off manager, shift trade are the other components of this tool that complete the solution.',
		},
		{
			title: 'Ticketing',
			subtitle1:
				'Mediatel Data ticketing is a web-based platform that makes it easy to centralize and handle multi-channel inbound requests in one ticketing system, by aggregating emails, phone calls, chat messages, internal notes, or data related to a customer, all in one place – 360 degrees Contact Center Ecosystem. ',
			subtitle2: '',
		},
	];

	const [current, setCurrent] = useState(features[0]);
	const [active, setActive] = useState(0);

	const handleClick = (event) => {
		setCurrent(features[event.target.getAttribute('data-quote')]);
		setActive(parseInt(event.target.getAttribute('data-quote')));
	};

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<div className={styles.textContainer}>
					<Typography variant='h4' className={styles.smallSubtitle}>
						A Great Range of Useful Features
					</Typography>
					<div className={styles.line}></div>
				</div>
				<div className={styles.infoContainer}>
					<div className={styles.tabsContainer}>
						{features.map((item, index) => {
							return (
								<div
									key={index}
									onClick={(event) => handleClick(event)}
									data-quote={index}
									className={
										index === active
											? cx(styles.active, styles.tab)
											: styles.tab
									}
								>
									<Typography variant='h6' className={styles.text}>
										{item.title}
									</Typography>
								</div>
							);
						})}
					</div>
					<div className={styles.feature}>
						<Typography
							variant='h6'
							dangerouslySetInnerHTML={{ __html: current.subtitle1 }}
							className={styles.subtitle}
						></Typography>
						<Typography
							variant='subtitle1'
							dangerouslySetInnerHTML={{ __html: current.subtitle2 }}
							className={styles.smallSubtitle}
						></Typography>
					</div>
				</div>
				<div className={styles.imageContainer}></div>
			</div>
		</div>
	);
};

export default Features;
