import React from 'react';
import { Typography } from '@mui/material';
import styles from './Introduction.module.css';

const Introduction = () => {
	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<div className={styles.intro}>
					<div className={styles.part1}>
						<div>
							<Typography variant='h6'>
								The VA solution can be deployed through both voice and messaging
								channels, helping to provide enhanced self-service interactions
								that engage every caller in natural language, not the
								stereotypical, robotic language that is often associated with
								new technologies.
							</Typography>
							<Typography variant='h6'>
								On this theme of improving customer experience, the Virtual
								Assistant will also enable you to offer 24-hour support,
								ensuring business continuity – especially amongst organizations
								with high call volumes and/ or text interactions.
							</Typography>
						</div>
						<div className={styles.animationContainer}>
							<div className={styles.shape1}></div>
							<div className={styles.shape2}></div>
						</div>
						<div>
							<Typography variant='h6'>
								While the contact center queue is peaking, simple tasks can be
								offset by the AI-driven conversations and voice-enabled
								self-service capabilities of Mediatel Data Virtual Assistant, as
								first call resolution increased by 20% in many cases.
							</Typography>
							<Typography variant='h6'>
								Not only this, but business insights will be increased – while
								automating either the entire or at least the beginning of the
								conversations, things become a little easier for the agents.
								Calls to agents that require user authentication have reduced
								their time by 40 seconds per call, due to authentication by
								Virtual Assistant.
							</Typography>
						</div>
					</div>
					<div className={styles.part2}>
						<div className={styles.info}>
							<Typography variant='h6'>
								All of this drives down costs, as agents have to handle fewer
								calls and – when they are called into action – will have lower
								handling times. It’s important to mentions that the VA and
								agents have a 360* view on all interactions a customer had,
								offering them a precious feeling of unified communications, when
								the integration is made with Mediatel Data Omni-Channel
								Platform.
							</Typography>
							<Typography variant='h6'>
								For the customer, wait times are reduced, straightforward
								queries are immediately resolved and customer satisfaction will
								likely be increased. Our latest internal market research showed
								that customer intentions are identified with an accuracy of over
								95% with Mediatel Data VA.
							</Typography>
							<Typography variant='h6'>
								The solution is designed to improve business, customer and
								agents results, providing a significant decrease in the need for
								agents to return customer in IVR and increasing overall customer
								satisfaction.
							</Typography>
						</div>
						<div className={styles.animationContainer}>
							<div className={styles.shape1}></div>
							<div className={styles.shape2}></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Introduction;
