import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import styles from './Blog.module.css';
import BlogPost from './BlogPost';
import LoadingSpinner from '../Loading Spinner/LoadingSpinner';

const Blog = () => {
	const [blogPosts, setBlogPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			setError('');
			try {
				const response = await fetch(process.env.REACT_APP_SANITY_BLOG);
				console.log(response);
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const { result } = await response.json();
				result.sort((a, b) => new Date(b.date) - new Date(a.date));
				setBlogPosts(result);
			} catch (err) {
				setError('Error getting the posts');
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []);

	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<div className={styles.titleContainer}>
					<Typography variant='h4' className={styles.titleText}>
						News & Events
					</Typography>
					<div className={styles.line}></div>
				</div>
				{loading && <LoadingSpinner />}
				{!loading &&
					!error &&
					blogPosts.length > 0 &&
					blogPosts.map((post, i) => <BlogPost key={i} content={post} />)}
				{!loading && error && <p>{error}</p>}
			</div>
		</div>
	);
};

export default Blog;
