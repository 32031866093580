import React, { useState } from 'react';
import logo from '../../_res/logo_mediatel.png';
import styles from './Navbar.module.css';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { MenuItems } from './MenuItems';
import classes from './Navbar.module.css';
import cx from 'classnames';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = ({ isTransparent }) => {
	const [clicked, setClicked] = useState(false);
	const location = useLocation();

	const handleClick = (e) => {
		e.preventDefault();
		setClicked(!clicked);
	};

	const transparentStyle = {
		background: 'transparent',
		boxShadow: 'none',
	};
	const opaqueStyle = {
		background: '#ffffff',
		boxShadow: ' 0px 4px 10px 0px rgb(0 0 0 / 18%)',
	};

	return (
		<div
			className={styles.navbar}
			style={isTransparent ? transparentStyle : opaqueStyle}
		>
			<Link to='/' className={styles.logo}>
				<img className={classes.imageLogo} src={logo} alt='logo' />
			</Link>
			<span className={styles.menuIcon} onClick={handleClick}>
				{clicked ? (
					<CloseIcon fontSize='large' />
				) : (
					<MenuIcon fontSize='large' />
				)}
			</span>
			<ul
				className={
					clicked ? cx(styles.listItems, styles.active) : styles.listItems
				}
			>
				{MenuItems.map((item, index) => {
					return (
						<li key={index} onClick={handleClick}>
							<NavLink
								to={item.url}
								// className={`${item.cName} ${({ isActive }) =>isActive ? styles.active : ''} `}
								className={({ isActive }) =>
									isActive ? `${item.cName} ${styles.active}` : item.cName
								}
								// isActive={(match, location) => {
								// 	if (location.pathname === item.url) {
								// 		return true;
								// 	}
								// 	return false;
								// }}
							>
								{item.title}
							</NavLink>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Navbar;
