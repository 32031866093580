import React from "react";
import Header from "./Header/Header";
import Items from "./Items/Items";
import Clients from "../Clients/Clients";
import ContactForm from "../ContactForm/ContactForm";
import styles from "./Services.module.css";

const Services = () => {
  return (
    <div className={styles.services}>
      <meta
        name='Mediatel Data Services'
        content='We help our clients focus on their core business and their most valuable asset - their customers, by offering consulting services and full assistance to provide a seamless contact center experience.'
      />
      <Header />
      <Clients />
      <Items />
      <ContactForm />
    </div>
  );
};

export default Services;
