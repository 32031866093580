export const MenuItems = [
	{
		title: 'Services',
		url: 'services',
		cName: 'links',
	},
	{
		title: 'Solutions',
		url: 'solutions',
		cName: 'links',
	},
	{
		title: 'Packages',
		url: 'packages',
		cName: 'links',
	},
	{
		title: 'About',
		url: 'about',
		cName: 'links',
	},
	{
		title: 'Blog',
		url: 'blog',
		cName: 'links',
	},
	{
		title: 'Contact',
		url: 'contact',
		cName: 'links',
	},
];
