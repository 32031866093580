import React from 'react';
import styles from './Words.module.css';
import { Typography } from '@mui/material';
import Typewriter from 'typewriter-effect';

export const Words = () => {
	return (
		<div className={styles.container}>
			<div>
				<Typography variant='h1' className={styles.title}>
					Develop&nbsp;
					<Typewriter
						options={{
							strings: ['Powerful', 'Flexible', 'Perfect'],
							autoStart: true,
							loop: true,
						}}
						onInit={(typewriter) => {
							typewriter.pauseFor(3000).deleteAll().start();
						}}
					/>
					<br />
					Packages
				</Typography>
				<Typography variant='h6' className={styles.subtitle}>
					With customer satisfaction as our core value we respond to the
					challenges launched by them, developing solutions that correspond to
					their business and technological needs regardless of the domain of
					activity in which they operate.
				</Typography>
			</div>
		</div>
	);
};

export default Words;
