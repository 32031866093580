import React from 'react';
import Header from './Header/Header';
import Clients from '../Clients/Clients';
import Features from './Features/Features';
// import News from './News/News';
import styles from './About.module.css';

const About = () => {
	return (
		<div className={styles.about}>
			<meta
				name='Contact Center Technology'
				content='Established in 1996, Mediatel Data Company has stood out over time as a pioneer of technology innovations in the Call and Contact center industry in Europe as well as in the USA, Asia, or South America.'
			/>
			<Header />
			<Clients />
			<Features />
			{/* <News /> */}
		</div>
	);
};
export default About;
