import React from 'react';
import styles from './Words.module.css';
import { Typography } from '@mui/material';

export const Words = () => {
	return (
		<div className={styles.container}>
			<>
				<Typography variant='h1' className={styles.title}>
					Mediatel Data
					<br /> Contact Center Technology
					<br /> Creator
				</Typography>
				<Typography variant='h6' className={styles.subtitle}>
					Established in 1996, Mediatel Data Company has stood out over time as
					a pioneer of technology innovations in the Call and Contact center
					industry in Europe as well as in the USA, Asia, or South America.
					<br />
					During this time we have developed a suite of applications
					interconnected with the telephony platform, such as the management and
					administration of non-vocal interactions – ticketing platform,
					workforce management, video inspector, chat bots, etc.
				</Typography>
			</>
		</div>
	);
};

export default Words;
