import React from 'react';
import { Typography } from '@mui/material';
import styles from './Content.module.css';

const Content = () => {
	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<div className={styles.textContainer}>
					<Typography variant='h4' className={styles.bigTitle}>
						Video Assistant through
						<br /> <span className={styles.customText}>Mediatel Data</span>{' '}
						Business Model
					</Typography>
				</div>
				<div className={styles.infoContainer}>
					<Typography variant='h6'>
						Due to this Pandemic situation and to the fact that the transition
						from office to remote work was not an easy process for some
						companies, the most requested product offered by Mediatel Data was
						the <strong>Video Inspector Application</strong> - standalone
						(initially named like this), but also the Quality Management
						application, tools that aim to facilitate an environment. Using our
						new solutions mobility costs reduced through 'work from home'
						opportunity (win-win situation).
					</Typography>
					<Typography variant='h6'>
						The clients were satisfied with the effort made by Mediatel Data for
						their most discreet transition from office to remote, and this
						brought extra satisfaction to the team. Therefore, customers did not
						have any problems with this change, and their data remained secure
						due to the fact that all connections are secured by VPN or through
						other technical solutions in this area.
					</Typography>
					<Typography variant='h6'>
						In a situation like this, you need to make sure that the system
						allows you to keep the same contact details of the customer
						relations department if the work will take place from home. Well,
						Mediatel Data managed to do this, integrating the Video Assistant in
						our full <strong>Contact Center Platform</strong>. The full 360
						<sup>0</sup> view helped both agents and customers, administrating
						in the same platform incoming and outgoing calls, as well as the
						emails, tickets, chat sessions, and full contact history.
					</Typography>
					<Typography variant='h6'>
						<strong>Video Assistant</strong> brought the perfect technology to
						bring people together and protecting them at the same time, which
						meant that for clients there was no noticeable difference in the
						location of the agents and after internal market research we fount
						out that the physical contact was reduced for many business-actions
						related with over 95%
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default Content;
