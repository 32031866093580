import React from 'react';

// import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import { Link, Typography } from '@mui/material';
import styles from './Blog.module.css';
import DescriptionComponent from './DescriptionComponent';
import BlogGallery from './BlogGallery';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BlogVideo from './BlogVideo';

const BlogPost = ({ content }) => {
	return (
		<div className={styles.eventContainer}>
			<Helmet>
				<meta name='description' content={content.description} />
				<meta property='og:title' content={content.title} />
			</Helmet>
			<div className={styles.infoContainer}>
				<div className={styles.blogTitle}>
					<Link
						href={content.link}
						target='_blank'
						rel='noopener'
						color='secondary'
					>
						<Typography variant='h6'>{content.title}</Typography>
					</Link>
				</div>
				<div className={styles.dateContainer}>
					<Typography variant='subtitle1' className={styles.location}>
						<LocationOnIcon className={styles.icon} />
						{content.location}
					</Typography>
					<Typography variant='subtitle1' className={styles.date}>
						{new Intl.DateTimeFormat('en-US', {
							month: 'long',
							day: 'numeric',
							year: 'numeric',
						}).format(new Date(content.date))}
					</Typography>
				</div>
			</div>
			<div className={styles.detailsContainer}>
				<div className={styles.blogText} style={{ whiteSpace: 'pre-line' }}>
					<DescriptionComponent description={content.description} />
				</div>

				{content.images?.length > 0 && (
					<div className={styles.galleryWrapper}>
						<BlogGallery images={content.images} />
					</div>
				)}

				{content.videos?.length > 0 && (
					<div
						className={styles.videoContainer}
						style={{ '--aspect-ratio': 2 / 3 }}
					>
						{content.videos.map((video) => (
							<BlogVideo key={video._key} videoURL={video.url} />
						))}
					</div>
				)}
			</div>
			<div className={styles.imageContainer}></div>
		</div>
	);
};

export default BlogPost;
