import React from 'react';
import { Typography, Button } from '@mui/material';
import styles from './TermsOfUse.module.css';

const TermsOfUse = () => {
	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<Typography variant='h4' className={styles.title}>
					Recommendations for Mediatel Data Call & Contact Center administration
				</Typography>

				<Typography variant='subtitle1'>
					For the good functioning of the Mediatel Data services and solutions,
					we come to meet our clients with a series of recommendations for their
					use.
				</Typography>

				<Typography variant='h6' className={styles.subtitle}>
					Insurances
				</Typography>
				<ul>
					<li>
						<Typography variant='subtitle1'>
							During the lifecycle and development/ upgrades and updates, best
							and latest practice and safety standards or well-established
							development standards are respected.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							Mediatel Data ensures that all employees understand their
							responsibilities and obligations regarding the processing of
							personal data and access to confidential information of third
							parties and their collaborators / clients.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							Database servers and applications only process data to meet
							customer goals. All data is stored in the environment and
							infrastructure provided by the customer and are entirely owned by
							them.
						</Typography>
					</li>
				</ul>

				<Typography variant='h6' className={styles.subtitle}>
					Roles and responsibilities
				</Typography>
				<ul>
					<li>
						<Typography variant='subtitle1'>
							It is important that the roles and responsibilities of the parties
							are clearly defined, from the beginning of the collaboration, in
							accordance with the internal security policy. Any change to these
							rights may have an impact on the services provided and we need to
							be informed. A management and registration of this type of
							information is required to be done internally.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							A plan on reporting, responding and treating incidents needs to be
							defined.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							All passwords and access rights are internally managed, with the
							recommendation to always ensure their complexity and their
							transmission is done only with protection.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							Databases and records always belong to the client and it is his
							responsibility to ensure proper operation, as well as archiving
							and accuracy.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							Performing the physical, physical and virtual back-up process is
							the task of the Operator, and monitoring is required to ensure
							completeness and restoration of the desired data.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							Encryption, archiving, pseudo-anonymization and anonymization is
							always done under internal protocols and using dedicated tools.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							We recommend increased security through firewalls and permanent
							monitoring of vital services from call and contact centers.
						</Typography>
					</li>
					<li>
						<Typography variant='subtitle1'>
							Mobile devices that have access to the computer system are
							pre-registered and internally pre-authorized.
						</Typography>
					</li>
				</ul>

				<Typography variant='subtitle1'>
					<strong>Disclaimer:</strong> All of the provided musical content on
					Mediatel Data applications is for promotional purposes and trial only.
				</Typography>
				<Typography variant='subtitle1'>
					Do not hesitate to ask for support and recommendation regarding
					Mediatel Data services and solutions!
				</Typography>

				<Button
					className={styles.btnSolutions}
					variant='contained'
					color='primary'
					size='large'
					href='/contact'
				>
					Contact Us
				</Button>
			</div>
		</div>
	);
};
export default TermsOfUse;
