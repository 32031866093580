import React from 'react';
import { Typography, Button } from '@mui/material';
import styles from './Items.module.css';
import ReactPlayer from 'react-player';
import { Fade } from 'react-awesome-reveal';

const Items = () => {
	const items = [
		{
			title: 'One Connected<br/> Customer Service Experience',
			subtitle:
				'Our forte has always been successfully tailoring our solutions to support your needs whether it is in the space of Call Center, Ticketing or Artificial Intelligence.',
			video:
				'https://www.youtube.com/watch?v=KZXkz69dik4&t=18s&ab_channel=MEDIATELDATA',
			link: 'virtual-assistant',
		},
		{
			title: 'Video Assistant Application',
			subtitle:
				'With its unique state-of-art technology, Video Assistant Application provides a transparent connection between agents and clients, easily operated & safe. In order to solve requests, this multi-purpose tool helps in checking details and building credibility without being necessary to be live in one place, breaking the barriers of timeand space.',
			video:
				'https://www.youtube.com/watch?v=MshNDhm_Vr8&ab_channel=MEDIATELDATA',
			link: 'video-assistant',
		},
	];

	return (
		<div className={styles.section}>
			<Fade bottom>
				<div className={styles.container}>
					<div className={styles.itemsContainer}>
						{items.map((item, index) => {
							return (
								<div className={styles.box} key={index}>
									<div className={styles.textContainer}>
										<div className={styles.title}>
											<Typography
												variant='h4'
												className={styles.text}
												dangerouslySetInnerHTML={{
													__html: item.title,
												}}
											></Typography>
											<div className={styles.line}></div>
										</div>
										<Typography variant='h6' className={styles.subtitle}>
											{item.subtitle}
										</Typography>
										<Button
											color='secondary'
											size='large'
											href={`/${item.link}`}
											variant='outlined'
										>
											Read more
										</Button>
									</div>
									<div className={styles.videoContainer}>
										<div className={styles.playerWrapper}>
											<ReactPlayer
												url={item.video}
												config={{
													youtube: {
														noCookie: true,
														embedOptions: {
															host: 'https://www.youtube-nocookie.com',
														},
													},
												}}
												className={styles.videoWrapper}
												playing
												muted
												loop
												controls
												width='100%'
												height='100%'
											/>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default Items;
