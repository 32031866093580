import React from 'react';
import { Typography } from '@mui/material';
import styles from './Introduction.module.css';

const Introduction = () => {
	return (
		<div className={styles.section}>
			<div className={styles.container}>
				<div className={styles.intro}>
					<div className={styles.info}>
						<Typography variant='h6'>
							A slew of reports are available for tracking and logging each
							support session, while special consideration is taken for securing
							the sensitive data stored during an interactive session – and
							their access. Photos and videos captured during a session are
							exclusively stored on the server – and by design can only be
							accessed or vetted by the agent, customer, or the agent’s
							supervisors.
						</Typography>
						<Typography variant='h6'>
							Staying true to its inheritance in the customer support paradigm,
							the <strong>Video Assistant</strong> gives the support agent a
							unique set of tools to control the flow of the interactive session
							– session initiation, screen sharing request, remote camera
							control, remote screenshot capture, and video recording. The
							number of assessments performed by an inspector in a day had
							increased by 15% (productivity).
						</Typography>
					</div>
					<div className={styles.animationContainer1}></div>
				</div>
				<div className={styles.intro}>
					<div className={styles.info}>
						<Typography variant='h6'>
							The agent's experience can be further enhanced with extra
							information extracted from clients' history and other
							interactions, while real-time face tracking and recognition makes
							it ideal for customer onboarding scenarios.
						</Typography>
						<Typography variant='h6'>
							The latest iteration of{' '}
							<strong>Mediatel's Contact Center solution</strong> leverages the
							full range of communication channels to further enhance the
							interactive customer experience. Marketing the access to the
							online platform led to an increase in enrolled customer by 7%.
						</Typography>
					</div>
					<div className={styles.animationContainer2}></div>
				</div>
				<div className={styles.intro}>
					<div className={styles.info}>
						<Typography variant='h6'>
							Whether you're looking for <strong>AI-powered Chatbots</strong>{' '}
							and queue management to reduce support costs, predictive dialer
							campaigns with thousands of calls or video chat and screen sharing
							to enable remote sales, Mediatel Data is your{' '}
							<strong>all-in-one solution</strong> for contact centers.
						</Typography>
						<Typography variant='h6'>
							Mediatel Data chooses to boost sales and services by embedding
							video, voice, chat, social media posts, comments, and co-browsing
							into customers' journeys.
						</Typography>
					</div>
					<div className={styles.animationContainer3}></div>
				</div>
			</div>
		</div>
	);
};
export default Introduction;
