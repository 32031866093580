import React, { useEffect } from 'react'
import styles from './Words.module.css'
// import { motion } from 'framer-motion';
import { Typography } from '@mui/material'

// import { Link } from 'react-router-dom';

export const Words = () => {
  const leftWords = [
    { title: 'ticketing', cName: 'word', color: 'var(--blue)' },
    { title: 'email', cName: 'word', color: 'var(--orange)' },
    { title: 'chat', cName: 'word', color: 'var(--green)' },
    { title: 'reporting', cName: 'word', color: 'var(--red)' },
    { title: 'support', cName: 'word', color: 'var(--blue)' },
    { title: 'digital', cName: 'word', color: 'var(--green)' },
    { title: 'social media', cName: 'word', color: 'var(--purple)' },
    { title: 'self service ivr', cName: 'word', color: 'var(--blue)' },
    { title: 'mcc phone pay', cName: 'word', color: 'var(--orange)' },
    { title: 'predictive', cName: 'word', color: 'var(--purple)' },
    { title: 'omni channel', cName: 'word', color: 'var(--grey)' },
    { title: 'multi channel', cName: 'word', color: 'var(--green)' },
    { title: 'recording', cName: 'word', color: 'var(--orange)' },
    { title: 'chat bot', cName: 'word', color: 'var(--grey)' },
    { title: 'workforce management', cName: 'word', color: 'var(--red)' },
    { title: 'contact center', cName: 'word', color: 'var(--blue)' },
    { title: 'real time', cName: 'word', color: 'var(--purple)' },
    { title: 'sms', cName: 'word', color: 'var(--red)' },
    { title: 'crm', cName: 'word', color: 'var(--orange)' },
    { title: 'artificial intelligence', cName: 'word', color: 'var(--blue)' }
  ]

  useEffect(() => {
    const getGartner = async () => {
      window.GartnerPI_Widget({
        size: 'line',
        theme: 'light',
        sourcingLink: 'https://gtnr.io/GAVSE4Kkm',
        widget_id: 'OGYxNTlhYTUtOTgxNy00NWI4LWJmYzMtMjgyZGZhMjA5NzI1',
        version: '2',
        container: document.querySelector('#widget-container')
      })
    }
    getGartner()
  }, [])

  return (
    <div className={styles.container}>
      <Typography variant='h1' className={styles.title}>
        Empower <br />
        Your Business
      </Typography>
      <div id='widget-container'></div>
      <Typography variant='h6' className={styles.subtitle}>
        The contact center solutions developed by our company have been
        numerously awarded for usability and innovative features.
      </Typography>
      <div className={styles.wordsContainer}>
        {leftWords.map((item, index) => {
          return (
            // <Link to='/#' key={index}>
            <Typography
              key={index}
              variant='subtitle2'
              className={styles.word}
              style={{
                backgroundColor: `${item.color}`
              }}
            >
              {item.title}
            </Typography>
            // </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Words
