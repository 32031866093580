import React from 'react';
import Header from './Header/Header';
import Clients from '../Clients/Clients';
import About from './AboutUs/About';
import Packages from './Packages/Packages';
import Solutions from './Solutions/Solutions';
import Assistants from './Assistants/Assistants';
import Services from './Services/Services';
import ContactForm from '../ContactForm/ContactForm';
import Testimonials from './Testimonials/Testimonials';
import FAQ from './FAQ/FAQ';
import News from '../News/News';

import styles from './Homepage.module.css';

const Homepage = () => {
	return (
		<div className={styles.homepage}>
			<Header />
			<Clients />
			<About />
			<Solutions />
			<Assistants />
			<Packages />
			<Services />
			<ContactForm />
			<Testimonials />
			<FAQ />
			<News />
		</div>
	);
};
export default Homepage;
